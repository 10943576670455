import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import Context from '../../models/client/Context';
import { SelectOption } from '../../models/SelectOption';
import { StepQuestion } from '../../models/StepQuestion';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';

interface Props {
	value?: SelectOption,
    className?: string,
	onChange: (value: { label: string, value: any } | null) => void
	dependantQuestions?: StepQuestion[],
	questionValues?: { [inputDefinitionID: number]: any },
}

const TermDropdown = (props: React.PropsWithChildren<Props>) => {
	const context: Context = useContext(AppContext);
	const questionValues = props.questionValues || {};

	const getTermOptions = () => {
		let options = ['12', '24', '36', '48', '60'];

		if (context.oemDealerType === 'BMH Dealer') {
			options = ['24', '36', '39', '48', '51', '52', '60', '63', '64', '72'];
		}
		else {
			const equipmentCodes = props.dependantQuestions?.map(x => questionValues[x.inputDefinitionID]);
			const dealTypeQ = props.dependantQuestions?.find(x => x.name === "Deal Type");
			const isConsumerDeal = (dealTypeQ && questionValues[dealTypeQ.inputDefinitionID]) === 'Consumer';
			const isTractor = equipmentCodes?.filter((code) => code === '25101923').length; // if it's a "Bobcat Compact Tractor", we show extended term options

			if (!isConsumerDeal || isTractor) {
				options.push('72');
			}

			if (isTractor) {
				options.push('84');
			}
		}

		return options.map(x => {
			return { label: x, value: x };
		});
	};
    return (
        <div>
			<DropdownInput searchable value={props.value} className={props.className} onChange={props.onChange} options={getTermOptions()}>
                {props.children}
            </DropdownInput>
        </div>
    );
}

export default TermDropdown;