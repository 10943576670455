import React, { useContext } from 'react';
import { Button} from 'react-bootstrap';
import { AppContext } from '../AppContext';


interface Props {
    fr8StarRedirectURL?: string
}
const Fr8starLink = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div style={{
            width: 755, borderRadius: 5, background: `#000 url("images/bg_fr8star.jpg") center center no-repeat`, backgroundSize: 'cover'
        }} className='d-flex align-items-center justify-content-center flex-wrap p-2 text-left text-white'>
            <div className="w-35">
                <a href={props.fr8StarRedirectURL} target="_blank">
                    <img className="d-block m-1" src="images/logo_fr8star.png" alt="FR8Star Logo" style={{ width: "100%", maxWidth: 150 }} />
                </a>
            </div>
            <div className="flex-1 p-0 m-2">
                <div style={{fontSize: 20}} className="font-weight-bolder">{context.translations.get('currency.competingbids')}</div>
                <div className="text-warning">{context.translations.get('currency.fromheavyhaulcompanies')}</div>
            </div>
            <div className="text-right m-1">
                <Button
                    className="btn btn-warning btn-block p-2 px-4 text-uppercase text-dark"
                    href={props.fr8StarRedirectURL} target="_blank">
                    {context.translations.get('currency.getfreequotes')}
                </Button>
            </div>
        </div>
    );
};
export default Fr8starLink;