import React, { useEffect, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';
import $ from 'jquery';
import { AppContext } from '../AppContext';

interface Props {
    loading: boolean,
    fatalError: boolean,
}

const Layout = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    useEffect(() => {
        if (!props.loading) {
            $('#layout-root').css('display', 'flex').hide().fadeIn();
        }
    }, [props.loading]);

    if (props.loading)
        return (
            <div id='loading-spinner' className='d-flex h-100 align-items-center justify-content-center'>
                <Spinner as='span' animation='border' />
            </div>
        );

    if (props.fatalError) {
        return (
            <div className='d-flex h-100 align-items-center justify-content-center'>
                <span>{context.translations.get('currency.fatalerror')}</span>
            </div>
        );
    }

    return (
        //<div id='layout-root' className={`${props.oem} flex-column h-100`}>
        <div id='layout-root' className={`flex-column h-100`}>
            <Header />
            <div className='bg d-flex flex-grow-1'>
                {props.children}
            </div>
            {context.vendorSettings["ShowFooter"] &&
                <Footer />
            }
        </div>
    );
}

export default Layout;