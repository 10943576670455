import React, { useContext } from 'react';
import { ListGroup } from "react-bootstrap";
import { AppContext } from "../AppContext";

const ChromeFaq = () => {
    const context = useContext(AppContext);

    return (
        <div>
            <h4 className='font-weight-bold'>{context.translations.get('Currency.FAQCookieHeader', 'Chrome')}</h4>
            <br />
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQCookie1', 'Chrome')}
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/chrome-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.getComponentFormat('Currency.FAQCookie2',
                        {
                            iconDots: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/vertical-dots.png'} alt='Browser Icon' />,
                            iconSettings: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/settings-icon.png'} alt='Browser Icon' />

                        }
                    )}
                </ListGroup.Item>
                <ListGroup.Item as='li'>{context.translations.get('Currency.FAQChromeCookie1')}</ListGroup.Item>
                <ListGroup.Item as='li'>{context.translations.get('Currency.FAQChromeCookie2')}</ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQChromeCookie3')}
                    <img style={{ height: '100%', width: '100%' }} src={'images/dbcs_faq/chrome-settings.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQChromeCookie4')}
                    <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/trash-icon.png'} alt='Browser Icon' />.
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQChromeCookie5')}
                    <img style={{ height: '100%', width: '100%' }} src={'images/dbcs_faq/chrome-clear-confirmation.png'} alt='Browser Icon' />
                </ListGroup.Item>
            </ListGroup>
            <br />
            <p>{context.translations.get('Currency.FAQChromeBlockerHeader')}</p>
            <br />
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQCookie1', 'Chrome')}
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/chrome-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.getComponentFormat('Currency.FAQChromeBlocker1', { iconDots: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/vertical-dots.png'} alt='Browser Icon' />})}
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQChromeBlocker2')}:
                    <ListGroup>
                        <ListGroup.Item>
                            {context.translations.get('Currency.FAQChromeBlocker3')}
                        </ListGroup.Item>
                        <ListGroup.Item>
                            {context.translations.get('Currency.FAQChromeBlocker4')}
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
            </ListGroup>
            <br />
            <div style={{ backgroundColor: 'lightgray', fontSize: '20px' }}>
                <p style={{ margin: '10px' }}>
                    {context.translations.get('Currency.FAQFooter1')}
                    <br /><br />
                    {context.translations.get('Currency.FAQFooter2')}
                    <br />
                    techsupport@gocurrency.com
                </p>
            </div>
        </div>
    );
}

export default ChromeFaq;