import React, { useContext, useEffect } from 'react';
import CurrencyUtility from '../../utilities/CurrencyUtility';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string,
    onChange?: (value: number) => void,
    cost?: number,
    downPayment?: number,
    currencyCode?: string
}

const LoanAmountDisplay = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    useEffect(() => {
        if (props.onChange && props.cost !== undefined)
            props.onChange((props.cost || 0) - (props.downPayment || 0));
    }, [props.cost, props.downPayment]);

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
            <h5>{context.translations.get('currency.requestedloanamount', CurrencyUtility.formatCurrency((props.cost || 0) - (props.downPayment || 0), undefined, props.currencyCode))}</h5>
            <i><small>{context.translations.get('currency.loanamountroundedtonearest', CurrencyUtility.formatCurrency(1, undefined, props.currencyCode))}</small></i>
            {props.children}
        </div>
    );
}

export default LoanAmountDisplay;