import React, { useContext } from 'react';
import { ListGroup } from "react-bootstrap";
import { AppContext } from "../AppContext";

const EdgeFaq = () => {
    const context = useContext(AppContext);

    return (
        <div>
            <h4 className='font-weight-bold'>{context.translations.get('Currency.FAQCookieHeader', 'Microsoft Edge')}</h4>
            <br />
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQCookie1', 'Edge')}
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/edge-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.getComponentFormat('Currency.FAQCookie2',
                        {
                            iconDots: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/horizontal-dots.png'} alt='Browser Icon' />,
                            iconSettings: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/settings-icon.png'} alt='Browser Icon' />
                        }
                    )}
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQEdgeCookie1')}<br />
                    <img style={{ width: '35%' }} src={'images/dbcs_faq/edge-cookies.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>{context.translations.get('Currency.FAQEdgeCookie2')}</ListGroup.Item>
                <ListGroup.Item as='li'>{context.translations.get('Currency.FAQEdgeCookie3')}</ListGroup.Item>
                <ListGroup.Item as='li'>{context.translations.get('Currency.FAQEdgeCookie4')}</ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQEdgeCookie5')}
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.getComponentFormat('Currency.FAQEdgeCookie6', { iconEdgeTrash: <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/expand-down.png'} alt='Browser Icon' /> })}
                </ListGroup.Item>
            </ListGroup>
            <br />
            <p className='font-weight-bold ml-2'>{context.translations.get('Currency.FAQEdgeBlockerHeader')}:</p>
            <ListGroup as='ol' numbered>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQCookie1', 'Edge')}
                    <img style={{ height: '1.5rem', marginLeft: '5px' }} src={'images/dbcs_faq/edge-logo.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.getComponentFormat('Currency.FAQCookie2', {
                        iconDots: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/horizontal-dots.png'} alt='Browser Icon' />,
                        iconSettings: <img style={{ height: '1.5rem' }} src={'images/dbcs_faq/settings-icon.png'} alt='Browser Icon' />
                    })}
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQEdgeBlocker1')}<br />
                    <img style={{ width: '35%' }} src={'images/dbcs_faq/edge-privacy.png'} alt='Browser Icon' />
                </ListGroup.Item>
                <ListGroup.Item as='li'>
                    {context.translations.get('Currency.FAQEdgeBlocker2')}
                </ListGroup.Item>
            </ListGroup>
            <br />
            <div style={{ backgroundColor: 'lightgray', fontSize: '20px' }}>
                <p style={{ margin: '10px' }}>
                    {context.translations.get('Currency.FAQFooter1')}
                    <br /><br />
                    {context.translations.get('Currency.FAQFooter2')}
                    <br />
                    techsupport@gocurrency.com
                </p>
            </div>
        </div>
    );
}

export default EdgeFaq;