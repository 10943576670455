import React, { useEffect, useState } from 'react';
import { AddressAutocompleteValue } from 'sandhills-react-bootstrap-ui';
import { QuestionGroup } from '../../models/QuestionGroup';
import { StepQuestion } from '../../models/StepQuestion';
import FlowProvider from '../../providers/FlowProvider';
import { AddressAutocompleteInput as Control } from 'sandhills-react-bootstrap-ui';
import { Country } from '../../models/Country';

interface Props {
    value?: string,
    question: StepQuestion,
    group: QuestionGroup,
    disabled?: boolean,
    className?: string,
    onChange: (question: StepQuestion, value: any) => void,
    countryIDForCountryRestrict?: any,
    placeholder?: string
}

const AddressAutocompleteInput = (props: React.PropsWithChildren<Props>) => {
    const [countryRestriction, setCountryRestriction] = useState<Country | null>(null);

    const handleChange = async (value: AddressAutocompleteValue) => {
        props.onChange(props.question, value.address?.street1);
        // For now, limit to search for more than 5 characters (US, Canada)
        if (value && !value.isManualEntry && props.group.questions.length > 1) {
            let state;
            let country;
            try {
                state = await FlowProvider.GetStateByCode(value.address?.state);
            } catch {
                //
            }

            try {
                country = await FlowProvider.GetCountryByCode(value.address?.country);
            } catch {
                //
            }

            const stateQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'statedropdown');
            if (stateQuestion && state) {
                props.onChange(stateQuestion, { label: state.stateName, value: state.stateID });
            }

            const cityQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'city');
            if (cityQuestion && value.address.city) {
                props.onChange(cityQuestion, value.address.city);
            }

            const countryQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'countrydropdown');
            if (countryQuestion && country) {
                props.onChange(countryQuestion, { label: country.countryName, value: country.countryID });
            }

            const postalQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'postalcode');
            if (postalQuestion && value.address.postalCode) {
                props.onChange(postalQuestion, value.address.postalCode);
            }
        }
    }

    const handleTextChange = (value: string) => {
        props.onChange(props.question, value);
    }

    useEffect(() => {
        if (props.countryIDForCountryRestrict) {
            FlowProvider.GetCountry(props.countryIDForCountryRestrict).then((result) => {
                if (result) {
                    setCountryRestriction(result);
                }
            });
        }
    }, [props.countryIDForCountryRestrict]);

    return (
        <Control
            apiKey='AIzaSyAVjLualIYMAHwEKHAadcV2ZXtaYMtUjiw'
            placeholder={props.placeholder}
            disabled={props.disabled}
            className={`${props.className} dropdown-input`}
            containerProps={{ className: 'label-colorize' }}
            manualEntryOnFailure
            value={props.value}
            onFocus={(e) => {
                // dumb workaround to stop browser autofill
                // @ts-ignore
                if (e && e.target.autocomplete) {
                    // @ts-ignore
                    e.target.autocomplete = 'do-not-fill';
                }
            }}
            onChange={handleChange}
            onTextChange={handleTextChange}
            additionalOptions={(countryRestriction?.isO2CountryCode) ? { componentRestrictions: { country: countryRestriction.isO2CountryCode } } : undefined}
        >
            {props.children}
        </Control>
    );
}

export default AddressAutocompleteInput;