import React, { useContext, useEffect } from 'react';
import { StepQuestion } from '../../models/StepQuestion';
import CurrencyUtility from '../../utilities/CurrencyUtility';
import { AppContext } from '../../AppContext';

interface Props {
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
    inputValues?: { [inputDefinitionID: number]: any },
    currencyCode?: string,
    onChange: (value: any | null) => void
}

const TotalCostOfEquipment = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);
    const questionValues = props.questionValues || {};
    const inputValues = props.inputValues || {};
    const onChange = props.onChange;

    const eqTypeQ = props.dependantQuestions?.find(x => x.name === "OEM Equipment Type");
    const eqMakeQ = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 1 Type In");
    const eqMakeDisplayQ = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 1 Display");
    const eqModelQDD = props.dependantQuestions?.find(x => x.name === "OEM Model Dropdown 1");
    const eqModelQTI = props.dependantQuestions?.find(x => x.name === "Equipment Model 1 Type In");
    const eqYearQ = props.dependantQuestions?.find(x => x.name === "Equipment Year");
    const eqTypeValue = (eqTypeQ && inputValues[eqTypeQ.inputDefinitionID]?.label) || undefined;
    const eqMakeValue = (eqMakeQ && questionValues[eqMakeQ.inputDefinitionID]) || (eqMakeDisplayQ && questionValues[eqMakeDisplayQ.inputDefinitionID]) || undefined;
    const eqModelValue = (eqModelQDD && questionValues[eqModelQDD.inputDefinitionID]) || (eqModelQTI && questionValues[eqModelQTI.inputDefinitionID]) || undefined;
    const eqYearValue = (eqYearQ && questionValues[eqYearQ.inputDefinitionID]) || undefined;

    const eqType2Q = props.dependantQuestions?.find(x => x.name === "OEM Equipment Type 2");
    const eqMake2Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 2 Type In");
    const eqMakeDisplay2Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 2 Display");
    const eqModel2QDD = props.dependantQuestions?.find(x => x.name === "OEM Model Dropdown 2");
    const eqModel2QTI = props.dependantQuestions?.find(x => x.name === "Equipment Model 2 Type In");
    const eqYear2Q = props.dependantQuestions?.find(x => x.name === "Secondary Equipment Year");
    const eqType2Value = (eqType2Q && inputValues[eqType2Q.inputDefinitionID]?.label) || undefined;
    const eqMake2Value = (eqMake2Q && questionValues[eqMake2Q.inputDefinitionID]) || (eqMakeDisplay2Q && questionValues[eqMakeDisplay2Q.inputDefinitionID]) || undefined;
    const eqModel2Value = (eqModel2QDD && questionValues[eqModel2QDD.inputDefinitionID]) || (eqModel2QTI && questionValues[eqModel2QTI.inputDefinitionID]) || undefined;
    const eqYear2Value = (eqYear2Q && questionValues[eqYear2Q.inputDefinitionID]) || undefined;

    const eqType3Q = props.dependantQuestions?.find(x => x.name === "OEM Equipment Type 3");
    const eqMake3Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 3 Type In");
    const eqMakeDisplay3Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 3 Display");
    const eqModel3QDD = props.dependantQuestions?.find(x => x.name === "OEM Model Dropdown 3");
    const eqModel3QTI = props.dependantQuestions?.find(x => x.name === "Equipment Model 3 Type In");
    const eqYear3Q = props.dependantQuestions?.find(x => x.name === "Equipment Year 3");
    const eqType3Value = (eqType3Q && inputValues[eqType3Q.inputDefinitionID]?.label) || undefined;
    const eqMake3Value = (eqMake3Q && questionValues[eqMake3Q.inputDefinitionID]) || (eqMakeDisplay3Q && questionValues[eqMakeDisplay3Q.inputDefinitionID]) || undefined;
    const eqModel3Value = (eqModel3QDD && questionValues[eqModel3QDD.inputDefinitionID]) || (eqModel3QTI && questionValues[eqModel3QTI.inputDefinitionID]) || undefined;
    const eqYear3Value = (eqYear3Q && questionValues[eqYear3Q.inputDefinitionID]) || undefined;

    const eqType4Q = props.dependantQuestions?.find(x => x.name === "OEM Equipment Type 4");
    const eqMake4Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 4 Type In");
    const eqMakeDisplay4Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 4 Display");
    const eqModel4QDD = props.dependantQuestions?.find(x => x.name === "OEM Model Dropdown 4");
    const eqModel4QTI = props.dependantQuestions?.find(x => x.name === "Equipment Model 4 Type In");
    const eqYear4Q = props.dependantQuestions?.find(x => x.name === "Equipment Year 4");
    const eqType4Value = (eqType4Q && inputValues[eqType4Q.inputDefinitionID]?.label) || undefined;
    const eqMake4Value = (eqMake4Q && questionValues[eqMake4Q.inputDefinitionID]) || (eqMakeDisplay4Q && questionValues[eqMakeDisplay4Q.inputDefinitionID]) || undefined;
    const eqModel4Value = (eqModel4QDD && questionValues[eqModel4QDD.inputDefinitionID]) || (eqModel4QTI && questionValues[eqModel4QTI.inputDefinitionID]) || undefined;
    const eqYear4Value = (eqYear4Q && questionValues[eqYear4Q.inputDefinitionID]) || undefined;

    const eqType5Q = props.dependantQuestions?.find(x => x.name === "OEM Equipment Type 5");
    const eqMake5Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 5 Type In");
    const eqMakeDisplay5Q = props.dependantQuestions?.find(x => x.name === "Equipment Manufacturer 5 Display");
    const eqModel5QDD = props.dependantQuestions?.find(x => x.name === "OEM Model Dropdown 5");
    const eqModel5QTI = props.dependantQuestions?.find(x => x.name === "Equipment Model 5 Type In");
    const eqYear5Q = props.dependantQuestions?.find(x => x.name === "Equipment Year 5");
    const eqType5Value = (eqType5Q && inputValues[eqType5Q.inputDefinitionID]?.label) || undefined;
    const eqMake5Value = (eqMake5Q && questionValues[eqMake5Q.inputDefinitionID]) || (eqMakeDisplay5Q && questionValues[eqMakeDisplay5Q.inputDefinitionID]) || undefined;
    const eqModel5Value = (eqModel5QDD && questionValues[eqModel5QDD.inputDefinitionID]) || (eqModel5QTI && questionValues[eqModel5QTI.inputDefinitionID]) || undefined;
    const eqYear5Value = (eqYear5Q && questionValues[eqYear5Q.inputDefinitionID]) || undefined;

    const eqCostQ = props.dependantQuestions?.find(x => x.name === "Equipment Cost");
    const eqCost2Q = props.dependantQuestions?.find(x => x.name === "Secondary Equipment Cost");
    const eqCost3Q = props.dependantQuestions?.find(x => x.name === "Equipment Cost 3");
    const eqCost4Q = props.dependantQuestions?.find(x => x.name === "Equipment Cost 4");
    const eqCost5Q = props.dependantQuestions?.find(x => x.name === "Equipment Cost 5");

    const totalEqCost = ((eqCostQ && questionValues[eqCostQ.inputDefinitionID]) || 0) + ((eqCost2Q && questionValues[eqCost2Q.inputDefinitionID]) || 0) +
        ((eqCost3Q && questionValues[eqCost3Q.inputDefinitionID]) || 0) + ((eqCost4Q && questionValues[eqCost4Q.inputDefinitionID]) || 0) +
        ((eqCost5Q && questionValues[eqCost5Q.inputDefinitionID]) || 0);

    const tradeInAmountQ = props.dependantQuestions?.find(x => x.name === "Trade In Allowance");
    const tradeInAmount2Q = props.dependantQuestions?.find(x => x.name === "Trade In Allowance 2");
    const tradeInAmount3Q = props.dependantQuestions?.find(x => x.name === "Trade In Allowance 3");
    const tradeInAmount4Q = props.dependantQuestions?.find(x => x.name === "Trade In Allowance 4");
    const tradeInAmount5Q = props.dependantQuestions?.find(x => x.name === "Trade In Allowance 5");
    const tradeInAmountValue = (tradeInAmountQ && questionValues[tradeInAmountQ.inputDefinitionID]) || 0;
    const tradeInAmount2Value = (tradeInAmount2Q && questionValues[tradeInAmount2Q.inputDefinitionID]) || 0;
    const tradeInAmount3Value = (tradeInAmount3Q && questionValues[tradeInAmount3Q.inputDefinitionID]) || 0;
    const tradeInAmount4Value = (tradeInAmount4Q && questionValues[tradeInAmount4Q.inputDefinitionID]) || 0;
    const tradeInAmount5Value = (tradeInAmount5Q && questionValues[tradeInAmount5Q.inputDefinitionID]) || 0;

    const tradeInLienAmountQ = props.dependantQuestions?.find(x => x.name === "Trade In Lien Amount");
    const tradeInLienAmount2Q = props.dependantQuestions?.find(x => x.name === "Trade In Lien Amount 2");
    const tradeInLienAmount3Q = props.dependantQuestions?.find(x => x.name === "Trade In Lien Amount 3");
    const tradeInLienAmount4Q = props.dependantQuestions?.find(x => x.name === "Trade In Lien Amount 4");
    const tradeInLienAmount5Q = props.dependantQuestions?.find(x => x.name === "Trade In Lien Amount 5");
    const tradeInLienAmountValue = (tradeInLienAmountQ && questionValues[tradeInLienAmountQ.inputDefinitionID]) || 0;
    const tradeInLienAmount2Value = (tradeInLienAmount2Q && questionValues[tradeInLienAmount2Q.inputDefinitionID]) || 0;
    const tradeInLienAmount3Value = (tradeInLienAmount3Q && questionValues[tradeInLienAmount3Q.inputDefinitionID]) || 0;
    const tradeInLienAmount4Value = (tradeInLienAmount4Q && questionValues[tradeInLienAmount4Q.inputDefinitionID]) || 0;
    const tradeInLienAmount5Value = (tradeInLienAmount5Q && questionValues[tradeInLienAmount5Q.inputDefinitionID]) || 0;

    const totalTradeInValue = -tradeInAmountValue - tradeInAmount2Value - tradeInAmount3Value - tradeInAmount4Value - tradeInAmount5Value
            + tradeInLienAmountValue + tradeInLienAmount2Value + tradeInLienAmount3Value + tradeInLienAmount4Value + tradeInLienAmount5Value;

    useEffect(() => {
        onChange(totalEqCost + totalTradeInValue);
        // eslint-disable-next-line
    }, [totalEqCost, totalTradeInValue]); 

    return (
        <div className='form-group'>
            <h4>{context.translations.get("Sandhills.Items").charAt(0).toUpperCase() + context.translations.get("Sandhills.Items").slice(1)}</h4>
            {eqTypeValue && eqYearValue && eqModelValue &&
                <div className='label'>
                    {eqTypeValue} - {eqYearValue} {context.translations.get(eqMakeValue) || 'Bobcat'} {eqModelValue}
                    <span className='float-right'>{CurrencyUtility.formatCurrency(eqCostQ && questionValues[eqCostQ.inputDefinitionID], undefined, props.currencyCode)}</span>
                </div>
            }
            {eqType2Value && eqYear2Value && eqModel2Value &&
                <div className='label'>
                    {eqType2Value} - {eqYear2Value} {context.translations.get(eqMake2Value) || 'Bobcat'} {eqModel2Value}
                    <span className='float-right'>{CurrencyUtility.formatCurrency(eqCost2Q && questionValues[eqCost2Q.inputDefinitionID], undefined, props.currencyCode)}</span>
                </div>
            }
            {eqType3Value && eqYear3Value && eqModel3Value &&
                <div className='label'>
                    {eqType3Value} - {eqYear3Value} {context.translations.get(eqMake3Value) || 'Bobcat'} {eqModel3Value}
                    <span className='float-right'>{CurrencyUtility.formatCurrency(eqCost3Q && questionValues[eqCost3Q.inputDefinitionID], undefined, props.currencyCode)}</span>
                </div>
            }
            {eqType4Value && eqYear4Value && eqModel4Value &&
                <div className='label'>
                    {eqType4Value} - {eqYear4Value} {context.translations.get(eqMake4Value) || 'Bobcat'} {eqModel4Value}
                    <span className='float-right'>{CurrencyUtility.formatCurrency(eqCost4Q && questionValues[eqCost4Q.inputDefinitionID], undefined, props.currencyCode)}</span>
                </div>
            }
            {eqType5Value && eqYear5Value && eqModel5Value &&
                <div className='label'>
                    {eqType5Value} - {eqYear5Value} {context.translations.get(eqMake5Value) || 'Bobcat'} {eqModel5Value}
                    <span className='float-right'>{CurrencyUtility.formatCurrency(eqCost5Q && questionValues[eqCost5Q.inputDefinitionID], undefined, props.currencyCode)}</span>
                </div>
            }
            <div className='label'>
                {context.translations.get("Sandhills.TotalCost")}: <span className='float-right'>{CurrencyUtility.formatCurrency(totalEqCost, undefined, props.currencyCode)}</span>
            </div>
            {totalTradeInValue !== 0 &&
                <div className='label'>
                    {context.translations.get("labelTradeEquity")}: <span className='float-right'>{CurrencyUtility.formatCurrency(totalTradeInValue, undefined, props.currencyCode)}</span>
                </div>
            }
        </div>
    )
}

export default TotalCostOfEquipment;