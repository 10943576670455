import React, { useContext, useMemo } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import Context from '../../models/client/Context';
import { SelectOption } from '../../models/SelectOption';
import { StepQuestion } from '../../models/StepQuestion';
import { AppContext } from '../../AppContext';
import AppProvider from '../../providers/AppProvider';

interface Props {
    question: StepQuestion,
    value?: SelectOption | null,
    className?: string,
    placeholder?: string,
    onChange: (question: StepQuestion, value: any) => void,
    dependantQuestions?: StepQuestion[],
}

const OEMEquipmentDropdown = (props: React.PropsWithChildren<Props>) => {
    const context: Context = useContext(AppContext);
    const equipmentTypeQuestion = props?.dependantQuestions?.find(x => x.name === `${context.oemDealerType} Equipment Type`);
    const manufacturerMapQuestion = props?.dependantQuestions?.find(q => q.name === 'OEM Equipment Code Manufacturer Map');

    const options: SelectOption[] = useMemo(() => {
        let optionList: SelectOption[] = [];
        if (manufacturerMapQuestion) {
            let optionsByMakes: any = {};
            var defaultMake = manufacturerMapQuestion.options?.find(x => x.value.toLowerCase() === 'default');

            equipmentTypeQuestion?.options?.forEach(option => {
                //'Default', 'Do Not Default'
                const makeMap: any = manufacturerMapQuestion.options?.find(x => x.value === option.value);
                var label = !makeMap ? defaultMake?.label : makeMap.label === 'Sandhills.DoNotDefault' ? 'Sandhills.Other' : makeMap.label;
                label = context.translations.get(label);
                if (!optionsByMakes[label])
                    optionsByMakes[label] = [];
                optionsByMakes[label].push({ ...option, label: context.translations.get(option.label) });
            });

            Object.keys(optionsByMakes).sort((a: string, b: string) => a.localeCompare(b)).forEach(make => {
                optionList.push({ label: make, options: optionsByMakes[make].sort((a: SelectOption, b: SelectOption) => a.label.localeCompare(b.label)), value: null, isDisabled: false });
            });
        }
        return optionList;
    }, [manufacturerMapQuestion, equipmentTypeQuestion, context.translations]);

    const handleChange = async (value: { label: string, value: any } | null) => {
        props.onChange(props.question, value);
        const manufacturerQuestion = props?.dependantQuestions?.find(q => q.modelPropertyName?.includes('Manufacturer'));
        const manufacturerMapQuestion = props?.dependantQuestions?.find(q => q.name === 'OEM Equipment Code Manufacturer Map');
        const categoryMapQuestion = props?.dependantQuestions?.find(x => x.name === 'OEM Equipment Code Category Map');
        const categoryQuestion = props?.dependantQuestions?.find(x => x.modelPropertyName?.includes('CategoryID'));

        if (manufacturerQuestion && manufacturerMapQuestion)
        {
            var option = manufacturerMapQuestion.options?.find(x => x.value === value?.value)
            var defaultOption = manufacturerMapQuestion.options?.find(x => x.value.toLowerCase() === 'default')
            if ((!option || option.label.toLowerCase() !== 'do not default') && defaultOption)
                props.onChange(manufacturerQuestion, (await AppProvider.GetTranslation(2, defaultOption.label))?.value || defaultOption.label);
            else if (option && option.label.toLowerCase() !== 'do not default')
                props.onChange(manufacturerQuestion, (await AppProvider.GetTranslation(2, option.label))?.value || option.label);
            else
                props.onChange(manufacturerQuestion, null);
        }

        const categoryValue = categoryMapQuestion?.options?.find(x => value?.value.toString() === x.label) || categoryMapQuestion?.options?.find(x => x.value === 1050);
        if (categoryMapQuestion && categoryQuestion && categoryValue)
            props.onChange(categoryQuestion, { label: categoryValue.label, value: Number(categoryValue.value) } );
    }

    const value: SelectOption = useMemo(() => {
        let label = '';

        options.forEach(opts => {
            opts.options.forEach((x: SelectOption) => {
                if (String(x.value) === String(props.value?.value)) {
                    label = x.label;
                }
            });
        });

        if (label === '') return { label: '', value: 0, isDisabled: false } as SelectOption;

        return { label: label, value: props.value?.value, isDisabled: false } as SelectOption;
    }, [props.value]);

    return (
        <DropdownInput className={props.className} placeholder={props.placeholder} searchable value={value} onChange={handleChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default OEMEquipmentDropdown;