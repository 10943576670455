import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { DataContext } from '../../shared/DataContext';
import { SaveApplication } from '../../models/SaveApplication';
import { StepQuestion } from '../../models/StepQuestion';
import { VendorStepFlow } from '../../models/VendorStepFlow';
import FlowProvider from '../../providers/FlowProvider';
import StatusStep from './StatusStep';
import ReviewStep from './ReviewStep';
import Step from './Step';
import { SaveApplicationResult } from '../../models/SaveApplicationResult';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import './Home.scss';
import AnimatedCheck from '../../shared/AnimatedCheck';
import SearchParamUtility from '../../utilities/SearchParamUtitlity';
import AnimatedExclamation from '../../shared/AnimatedExclamation';
import SocialSecurityNumberModal from './SocialSecurityNumberModal';
import { FaExternalLinkAlt } from 'react-icons/fa';
import OfferTable from './OfferTable';
import TagManager from 'react-gtm-module';
import ValidationUtility from '../../utilities/ValidationUtility';
import ValidationResult from '../../models/client/ValidationResult';
import AppProvider from '../../providers/AppProvider';

const uniqueSessionID = uuid();

const Home = () => {
    const context = useContext(AppContext);
    const [searchParams] = useSearchParams();

    const [loadingFlow, setLoadingFlow] = useState(true);
    const [loadingStep, setLoadingStep] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [flow, setFlow] = useState<VendorStepFlow | null | undefined>(null);
    const [inputValueDictionary, setInputValueDictionary] = useState<{ [inputDefinitionID: number]: any }>({}); // This is the value of the input control
    const [questionValueDictionary, setQuestionValueDictionary] = useState<{ [inputDefinitionID: number]: any }>({}); // This is the value to be saved on the application
    const [hasError, setHasError] = useState(false); //Disables StartOver and Back button on error
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [marketingPrograms, setMarketingPrograms] = useState<any>(null);
    const uniqueStepIndexHistory = useRef<number[]>([]);
    const onNextShouldReview = useRef<boolean>(false);
    const conditionalCheckTimeout = useRef<NodeJS.Timeout | null>(null);
    const allFlowQuestions = useRef<StepQuestion[]>([]);

    const applicationInProgress = useRef<SaveApplicationResult | null>(null);
    const isSubmitting = useRef(false);

    const [errorQuestions, setErrorQuestions] = useState<{ [key: number]: string | true | undefined }>({});

    const [showSSNModal, setShowSSNModal] = useState(false);

    const [redirectTimer, setRedirectTimer] = useState(0);
    const redirectTimerValue = useRef(0);

    useEffect(() => {
        redirectTimerValue.current = redirectTimer;
    }, [redirectTimer]);

    useEffect(() => {
        const listingID = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'listingID');
        const aipid = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'aipid');
        const encryptedCRMID = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'crmid');
        const dealType = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'dealType');
        const utmSource = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Source') || null;
        const cultureCode = SearchParamUtility.getCaseInsensitiveParam(searchParams, 'culture');

        AppProvider.LogApplicationInsights({
            message: 'Loading flow',
            listingID,
            encryptedCRMID,
            dealType,
            uniqueSessionID,
            userID: context.uniqueClientID
        });

        FlowProvider.GetFormForVendor(uniqueSessionID, context.vendorID, context.topLevelPartnerVendorID, listingID ? parseInt(listingID) : undefined, encryptedCRMID, dealType, aipid ? parseInt(aipid) : undefined, utmSource, cultureCode).then((result) => {
            if (result) {
                allFlowQuestions.current = result?.steps.map(step => step.questionGroups.map(group => group.questions)).flat(2);
                setCurrentStepIndex(result.startIndex);
                setFlow(result);
                // initQuestionInputDictionaries(result.initialInputValues);
                setValueDictionariesFromResponse(result.initialInputValues);
            } else {
                setErrorMessage(context.translations.get('currency.failedtoloadapplication'));
            }
        }).finally(() => {
            setLoadingFlow(false);
        });
    }, []);

    useEffect(() => {
        if (!uniqueStepIndexHistory.current.includes(currentStepIndex) && !loadingFlow) {
            uniqueStepIndexHistory.current.push(currentStepIndex);
        }

        toast.dismiss();

        const elements = document.getElementsByTagName('body');
        if (elements.length > 0) {
            elements[0].scrollTo(0, 0);
        }
    }, [currentStepIndex, loadingFlow]);

    // const initQuestionInputDictionaries = (initialInputValues: { [key: number]: any }) => {
    //     if (initialInputValues) {
    //         setInputValueDictionary(initialInputValues);

    //         let questionValues: { [key: number]: any } = {};
    //         for (let key of Object.keys(initialInputValues)) {
    //             questionValues[parseInt(key)] = initialInputValues[parseInt(key)]?.value ?? initialInputValues[parseInt(key)] ?? null;
    //         }

    //         setQuestionValueDictionary(questionValues);
    //     }
    // }

    const setValueDictionariesFromResponse = (derivedInputValues: { [key: number]: any } | null) => {
        AppProvider.LogApplicationInsights({
            message: 'Input Values From Response',
            userID: context.uniqueClientID,
            currentInputValues: inputValueDictionary,
            currentQuestionValues: questionValueDictionary,
            inputValuesToMerge: derivedInputValues,
            uniqueSessionID
        });

        if (derivedInputValues !== null && derivedInputValues !== undefined) {
            try {
                // Do not use structuredClone, it isn't supported across the board
                // Saw errors 'Can't find variable: structuredClone' in insight logs
                let inputDict = JSON.parse(JSON.stringify(inputValueDictionary));
                let questionDict = JSON.parse(JSON.stringify(questionValueDictionary));

                for (let key of Object.keys(derivedInputValues)) {
                    // Only want to set new values, not overwrite existing
                    // 12/20/2023 - This is no longer true, we want to overwrite existing values (deal type via category)
                    inputDict[parseInt(key)] = derivedInputValues[parseInt(key)];
                    questionDict[parseInt(key)] = derivedInputValues[parseInt(key)]?.value ?? derivedInputValues[parseInt(key)] ?? null;
                }

                AppProvider.LogApplicationInsights({
                    message: 'Merged Input Values',
                    updatedQuestionValues: questionDict,
                    updatedInputValues: inputDict,
                    uniqueSessionID,
                    userID: context.uniqueClientID
                });

                setInputValueDictionary(inputDict);
                setQuestionValueDictionary(questionDict);
            } catch (e: any) {
                AppProvider.LogApplicationInsights({
                    message: 'Error Merging Input Values',
                    errorName: e?.name,
                    errorMessage: e?.message,
                    uniqueSessionID,
                    userID: context.uniqueClientID
                });
            }
        }
    }

    const getFlowCopy = (): VendorStepFlow | undefined => {
        if (flow) {
            return {
                ...flow,
                steps: [...flow?.steps.map(step => ({
                    ...step,
                    conditionalStepQuestion: step.conditionalStepQuestion ? { ...step.conditionalStepQuestion } : null,
                    questionGroups: [...step.questionGroups.map(group => ({
                        ...group,
                        questions: [...group.questions.map(question => ({
                            ...question
                        }))]
                    }))]
                }))]
            };
        }
    }

    const getAllConditionalQuestions = (inputDefinitionID: number) => {
        return flow?.steps.map(x => x.questionGroups.map(y => y.questions.filter(z => z.conditionalQuestion?.inputDefinitionID === inputDefinitionID))).flat(2);
    }

    const setWindowUnloadListener = () => {
        window.onbeforeunload = () => context.translations.get('currency.leaveapplicationconfirmation');
    }

    const removeWindowUnloadListener = () => {
        window.onbeforeunload = null;
    }

    const startRedirectTimer = (url: string) => {
        if (!url) return;

        const int = setInterval(() => {
            setRedirectTimer(prev => prev + 1);
            if (redirectTimerValue.current >= 5) {
                clearInterval(int);
                window.open(url, '_blank');
            }
        }, 1000);
    }

    const validateCurrentStep = async (): Promise<boolean> => {
        let hasError = false;

        let errors: { [key: number]: string | true | undefined } = {};
        // Do all required validations
        flow?.steps[currentStepIndex].questionGroups.forEach(group => {
            group.questions.forEach(question => {
                if (question.isRequired && question.show && (questionValueDictionary[question.inputDefinitionID] === null || // If required and no value
                    questionValueDictionary[question.inputDefinitionID] === undefined ||
                    questionValueDictionary[question.inputDefinitionID].toString().trim() === '' ||
                    (questionValueDictionary[question.inputDefinitionID] === false && question.controlType !== 'YESNORADIO'))
                ) {
                    errors[question.inputDefinitionID] = true;
                    hasError = true;
                } else {
                    errors[question.inputDefinitionID] = undefined;
                }
            });
        });

        // Do all custom validations
        let validationProms: Promise<ValidationResult>[] = [];
        flow?.steps[currentStepIndex].questionGroups.forEach(group => {
            group.questions.forEach(question => {
                if (question.show && question.validation && errors[question.inputDefinitionID] === undefined) {
                    validationProms.push(ValidationUtility.executeValidation(question, questionValueDictionary, context.translations, context.languageID));
                }
            });
        });

        let validationResults = await Promise.all(validationProms);
        validationResults.forEach(result => {
            if (result.isValid === false) {
                errors[result.inputDefinitionID] = result.message;
                hasError = true;
            } else {
                errors[result.inputDefinitionID] = undefined;
            }
        });

        setErrorQuestions(errors);

        return !hasError;
    }

    const handleNextClick = async () => {
        toast.dismiss();

        setLoadingStep(true);
        validateCurrentStep().then(valid => {
            if (valid) {
                setWindowUnloadListener();

                // Custom event for GTM
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'next',
                        currentStepIndex: currentStepIndex + 1,
                        currentStepName: flow?.steps[currentStepIndex].stepName
                    }
                });

                const input: SaveApplication = {
                    applicationID: null, // This will never have a value here nor be used server side
                    questionValues: questionValueDictionary,
                    listingID: flow?.listingID || null,
                    crmid: flow?.crmid || null,
                    uniqueSessionID,
                    referrer: document.referrer,
                    accountCRMID: flow?.accountCRMID || null,
                    vendorID: context.vendorID,
                    topLevelPartnerVendorID: context.topLevelPartnerVendorID,
                    highestTopLevelPartnerVendorID: context.highestTopLevelPartnerVendorID,
                    languageID: context.languageID,
                    applicationInProgressID: applicationInProgress.current?.applicationInProgressID || null,
                    uniqueClientID: context.uniqueClientID,
                    currentStepID: flow!.steps[currentStepIndex].stepID,
                    utmCampaign: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Campaign') || null,
                    utmContent: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Content') || null,
                    utmMedium: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Medium') || null,
                    utmSource: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Source') || null,
                    utmType: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Type') || null,
                    fullSocialSecurityNumber: null,
                    latitude: null,
                    longitude: null,
                    gaClientID: context.cookies.get('_ga'),
                }

                AppProvider.LogApplicationInsights({
                    message: 'Saving application progress',
                    input,
                    currentStep: flow!.steps[currentStepIndex].stepName,
                    uniqueSessionID,
                    userID: context.uniqueClientID
                });

                return FlowProvider.SaveApplicationProgressGetNextStep(input).then((result) => {
                    if (result) {
                        applicationInProgress.current = result;

                        let nextStepIndex = flow!.steps.length // One more than exists will trigger review step

                        if (!onNextShouldReview.current && result.nextStepID !== null) {
                            nextStepIndex = flow!.steps.findIndex(x => x.stepID === result.nextStepID);
                        }

                        setValueDictionariesFromResponse(result.derivedInputValues);
                        setCurrentStepIndex(nextStepIndex);
                        setHasError(false);
                    }
                    else {
                        setHasError(true);
                    }
                });
            }
        }).finally(() => {
            setLoadingStep(false);
        });
    }

    const handleSubmitClick = (fullSSN?: string) => {
        if (isSubmitting.current) return;
        isSubmitting.current = true;
        setLoadingStep(true);

        // Custom event for GTM
        // Only want one submit, so don't fire again if we already have an applicationID
        if (!applicationInProgress.current?.applicationID) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'submit',
                    currentStepIndex: currentStepIndex + 1,
                    currentStepName: 'Review'
                }
            });
        }

        const input: SaveApplication = {
            applicationID: applicationInProgress.current?.applicationID || null,
            fullSocialSecurityNumber: fullSSN || null,
            questionValues: questionValueDictionary,
            uniqueSessionID,
            referrer: document.referrer,
            listingID: flow?.listingID || null,
            crmid: flow?.crmid || null,
            accountCRMID: flow?.accountCRMID || null,
            vendorID: context.vendorID,
            topLevelPartnerVendorID: context.topLevelPartnerVendorID,
            highestTopLevelPartnerVendorID: context.highestTopLevelPartnerVendorID,
            languageID: context.languageID,
            applicationInProgressID: applicationInProgress.current?.applicationInProgressID || null,
            uniqueClientID: context.uniqueClientID,
            currentStepID: null,
            utmCampaign: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Campaign') || null,
            utmContent: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Content') || null,
            utmMedium: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Medium') || null,
            utmSource: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Source') || null,
            utmType: SearchParamUtility.getCaseInsensitiveParam(searchParams, 'UTM_Type') || null,
            latitude: null,
            longitude: null,
            gaClientID: context.cookies.get('_ga'),
        }

        AppProvider.LogApplicationInsights({
            message: 'Submitting application',
            input,
            currentStep: 'Review',
            uniqueSessionID,
            userID: context.uniqueClientID
        });

        FlowProvider.SubmitApplication(input).then((result) => {
            if (result) {
                applicationInProgress.current = result;

                if (!result.isFullSSNRequired || (result.isFullSSNRequired && fullSSN)) {
                    removeWindowUnloadListener();
                    setCurrentStepIndex(flow!.steps.length + 1);
                    if (!applicationInProgress.current?.didNotPassAnyLenders && !applicationInProgress.current?.hideSiteJabber && !applicationInProgress.current?.passedExternalLenders) {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'SiteJabber',
                                first_name: applicationInProgress?.current?.primaryContactFirstName,
                                last_name: applicationInProgress?.current?.primaryContactLastName,
                                email: applicationInProgress?.current?.primaryContactEmailAddress,
                                phone: applicationInProgress?.current?.primaryContactPhoneNumber,
                                order_id: applicationInProgress?.current?.applicationID
                            }
                        });
                    }
                    setLoadingStep(false);
                    if (result.didNotPassAnyLenders && result.finMarketRedirectURL) {
                        startRedirectTimer(result.finMarketRedirectURL);
                    }
                } else {
                    setShowSSNModal(true);
                }
            } else {
                setLoadingStep(false);
            }
        }).finally(() => {
            isSubmitting.current = false;
        });
    }

    const handleSSNModalSubmit = (ssn: string | null) => {
        if (ssn) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'confirmSSN',
                    currentStepIndex: currentStepIndex + 1,
                    currentStepName: 'Review'
                }
            });

            handleSubmitClick(ssn); // Resubmit with SSN
        } else {
            // Need to just show the no lenders found page
            applicationInProgress.current = { ...applicationInProgress.current!, isFullSSNRequired: false, didNotPassAnyLenders: true, finMarketRedirectURL: '' };
            setCurrentStepIndex(flow!.steps.length + 1);
            setLoadingStep(false);
            // Do not want to redirect to FinMarket if they cancel the SSN modal
            // startRedirectTimer(applicationInProgress.current.finMarketRedirectURL ?? '');
        }

        setShowSSNModal(false);
        removeWindowUnloadListener();
    }

    const handleEditStepClick = (stepIndex: number) => {
        setCurrentStepIndex(stepIndex);
        onNextShouldReview.current = true; // They are editing a step, clicking next should take them back to review
    }

    const handleBackClick = async () => {
        // Custom event for GTM
        TagManager.dataLayer({
            dataLayer: {
                event: 'back',
                currentStepIndex: currentStepIndex + 1,
                currentStepName: currentStepIndex === flow?.steps.length ? 'Review' : flow?.steps[currentStepIndex].stepName
            }
        });

        AppProvider.LogApplicationInsights({
            message: 'Back button clicked',
            uniqueSessionID,
            currentStep: currentStepIndex === flow?.steps.length ? 'Review' : flow?.steps[currentStepIndex].stepName,
            userID: context.uniqueClientID
        });

        // Clear any invalid data from the current step
        // This will keep invalid data from being saved if they go back and forth between steps
        let validationProms: Promise<ValidationResult>[] = [];
        if (currentStepIndex < flow!.steps.length) {
            flow?.steps[currentStepIndex].questionGroups.forEach(group => {
                group.questions.forEach(question => {
                    if (question.show && question.validation) {
                        validationProms.push(ValidationUtility.executeValidation(question, questionValueDictionary, context.translations, context.languageID));
                    }
                });
            });
        }

        onNextShouldReview.current = false;

        // Go to the step that they hit before this one
        setCurrentStepIndex(curr => uniqueStepIndexHistory.current[uniqueStepIndexHistory.current.indexOf(curr) - 1]);

        // Moving this after current step so that we aren't waiting on validation to go back
        const validationResults = await Promise.all(validationProms);
        validationResults.forEach(result => {
            if (result.isValid === false) {
                setQuestionValueDictionary(current => ({ ...current, [result.inputDefinitionID]: null }));
                // This sucks - empty string is not a valid value for all inputs
                // But, this is really only used for phone & email, so it should be fine
                setInputValueDictionary(current => ({ ...current, [result.inputDefinitionID]: '' }));
            }
        });
    }

    const handleStartOverClick = () => {
        // Custom event for GTM
        TagManager.dataLayer({
            dataLayer: {
                event: 'startOver',
                currentStepIndex: currentStepIndex + 1,
                currentStepName: currentStepIndex >= flow!.steps.length ? 'Review' : flow?.steps[currentStepIndex].stepName
            }
        });

        AppProvider.LogApplicationInsights({
            message: 'Start over button clicked',
            uniqueSessionID,
            currentStep: currentStepIndex >= flow!.steps.length ? 'Review' : flow?.steps[currentStepIndex].stepName,
            userID: context.uniqueClientID
        });

        onNextShouldReview.current = false;
        uniqueStepIndexHistory.current = [];
        setCurrentStepIndex(flow?.startIndex || 0);
    }

    const performConditionalQuestionCheck = (conditionalQuestions: StepQuestion[], currentValue: any, updFlow: VendorStepFlow | undefined = undefined) => {
        let upd = updFlow ?? getFlowCopy();
        let updQuestions = upd?.steps.map(step => step.questionGroups.map(group => group.questions)).flat(2);
        
        if (updQuestions) {
            for (let question of conditionalQuestions) {
                let match = updQuestions.find(x => x.inputDefinitionID === question.inputDefinitionID);

                if (match) {
                    match.show = performConditionalCheck(match, currentValue);
                    if (match.show === false)
                        handleQuestionValueChangeNested(match, flow?.initialInputValues[match.inputDefinitionID] ?? null, upd);
                }
            }
        }

        // Need to show any groups that have visible question and hide others
        let updGroups = upd?.steps.map(x => x.questionGroups).flat();

        if (updGroups) {
            for (let group of updGroups) {
                group.show = group.questions.some(x => x.show);
            }
        }
        if (!updFlow)
            setFlow(upd);
    }

    const performConditionalCheck = (question: StepQuestion, currentValue: any) => {
        switch (question.conditionalMatchOperator?.toLowerCase()) {
            case 'hasdata':
                return currentValue !== null && currentValue !== undefined && currentValue.toString().trim() !== '';
            case 'doesnothavedata':
                return  currentValue === null || currentValue === undefined || currentValue.toString().trim() === '';
            default:
                return question.conditionalQuestionTriggers?.some(x => x.toLowerCase() === currentValue?.toString().toLowerCase()) === true;
        }
    }

    const handleQuestionValueChange = (question: StepQuestion, inputValue: any) => {
        const questionValue = inputValue?.value ?? inputValue?.industryID ?? inputValue ?? null;

        // If there are any steps conditional on the question changed, do not skip back to review
        // If so, do not skip back to the review step
        // Also remove history beyond this point
        // Only need to check subsequent steps
        for (let stepIndex = currentStepIndex + 1; stepIndex < flow!.steps.length; stepIndex++) {
            let step = flow!.steps[stepIndex];

            if (step.conditionalStepQuestion && step.conditionalStepQuestion.inputDefinitionID === question.inputDefinitionID) {
                uniqueStepIndexHistory.current.splice(uniqueStepIndexHistory.current.indexOf(currentStepIndex) + 1);

                if (!performConditionalCheck(step.conditionalStepQuestion, questionValueDictionary[step.conditionalStepQuestion.inputDefinitionID])) {
                    step.questionGroups.forEach(group => {
                        group.questions.forEach(q => {
                            const defaultValue = q.controlType.toLowerCase() == 'dropdowninput' ? { label: '', value: '' } : '';
                            const initVal = flow?.initialInputValues[q.inputDefinitionID] ?? defaultValue;
                            const initQValue = initVal?.value ?? initVal?.industryID ?? initVal ?? defaultValue;

                            setQuestionValueDictionary(current => ({ ...current, [q.inputDefinitionID]: initQValue }));
                            setInputValueDictionary(current => ({ ...current, [q.inputDefinitionID]: initVal }));
                        });
                    });
                }

                onNextShouldReview.current = false;
            }
        }

        // Check to see if conditionals now need shown
        // Gets all questions that are conditional on this one
        const questions = getAllConditionalQuestions(question.inputDefinitionID);
        if (questions && questions.length > 0) {
            if (conditionalCheckTimeout.current)
                clearTimeout(conditionalCheckTimeout.current);

            conditionalCheckTimeout.current = setTimeout(() => {
                performConditionalQuestionCheck(questions, questionValue);
            }, 500);
        }

        setQuestionValueDictionary(current => ({ ...current, [question.inputDefinitionID]: questionValue }));
        setInputValueDictionary(current => ({ ...current, [question.inputDefinitionID]: inputValue }));
    }

    const handleQuestionValueChangeNested = (question: StepQuestion, inputValue: any, updFlow: VendorStepFlow | undefined) => {
        const questionValue = inputValue?.value ?? inputValue?.industryID ?? inputValue ?? null;

        // If there are any steps conditional on the question changed, do not skip back to review
        // If so, do not skip back to the review step
        // Also remove history beyond this point
        // Only need to check subsequent steps
        for (let stepIndex = currentStepIndex + 1; stepIndex < flow!.steps.length; stepIndex++) {
            let step = flow!.steps[stepIndex];

            if (step.conditionalStepQuestion && step.conditionalStepQuestion.inputDefinitionID === question.inputDefinitionID) {
                uniqueStepIndexHistory.current.splice(uniqueStepIndexHistory.current.indexOf(currentStepIndex) + 1);

                 if (!performConditionalCheck(step.conditionalStepQuestion, questionValueDictionary[step.conditionalStepQuestion.inputDefinitionID])) {
                    step.questionGroups.forEach(group => {
                        group.questions.forEach(q => {
                            const defaultValue = q.controlType.toLowerCase() == 'dropdowninput' ? { label: '', value: '' } : '';
                            const initVal = flow?.initialInputValues[q.inputDefinitionID] ?? defaultValue;
                            const initQValue = initVal?.value ?? initVal?.industryID ?? initVal ?? defaultValue;
                            setQuestionValueDictionary(current => ({ ...current, [q.inputDefinitionID]: initQValue }));
                            setInputValueDictionary(current => ({ ...current, [q.inputDefinitionID]: initVal }));
                        });
                    });
                }

                onNextShouldReview.current = false;
            }
        }

        // Check to see if conditionals now need shown
        // Gets all questions that are conditional on this one
        const questions = updFlow?.steps.map(x => x.questionGroups.map(y => y.questions.filter(z => z.conditionalQuestion?.inputDefinitionID === question.inputDefinitionID))).flat(2)
        if (questions && questions.length > 0) {
            performConditionalQuestionCheck(questions, questionValue, updFlow);
        }

        setQuestionValueDictionary(current => ({ ...current, [question.inputDefinitionID]: questionValue }));
        setInputValueDictionary(current => ({ ...current, [question.inputDefinitionID]: inputValue }));
    }

    return (
        <DataContext.Provider value={{
            marketingPrograms: marketingPrograms,
            setMarketingPrograms: setMarketingPrograms,
        }}>
            <div className='w-100 d-flex home-container justify-content-center'>
                {/* Specific hidden inputs for GA / marketing */}
                <input id='user-id' type='hidden' name='user_id' value={context.uniqueClientID} />
                <input id='unique-session-id' type='hidden' name='unique_session_id' value={uniqueSessionID} />

                <SocialSecurityNumberModal title='Confirm SSN' show={showSSNModal} onHide={handleSSNModalSubmit} />

                <div className='d-flex flex-column align-items-center step-container'>
                    {context.vendorSettings["ShowHeading"] &&
                        <div style={{ width: 300 }} className='img-header text-center my-3 my-md-5'>
                            <img style={{ maxWidth: 300 }} src='images/currency.png' alt='Currency' />
                            <span style={{ fontSize: 22 }} className='text-uppercase text-condensed'>{context.translations.get('currency.fastandeasyfinancing')}</span>
                        </div>
                    }

                    {!flow &&
                        <StatusStep loading={loadingFlow} message={errorMessage} />
                    }

                    {flow && flow.steps && flow.steps.map((x, i) => (
                        <Step
                            key={x.stepID}
                            step={x}
                            loading={loadingStep}
                            inputValueDictionary={inputValueDictionary}
                            questionValueDictionary={questionValueDictionary}
                            allFlowQuestions={allFlowQuestions.current}
                            errors={errorQuestions}
                            disablePrevious={hasError}
                            isLastStep={i === flow.steps.length - 1}
                            visible={i === currentStepIndex}
                            onStartOver={i !== 0 ? handleStartOverClick : undefined}
                            onQuestionValueChange={handleQuestionValueChange}
                            onBack={i !== 0 ? handleBackClick : undefined}
                            onNext={handleNextClick} />
                    ))}
                    {flow && currentStepIndex === flow?.steps.length &&
                        <ReviewStep
                            flow={flow}
                            stepIndexHistory={uniqueStepIndexHistory.current}
                            loading={loadingStep}
                            questionValueDictionary={questionValueDictionary}
                            inputValueDictionary={inputValueDictionary}
                            allFlowQuestions={allFlowQuestions.current}
                            onBack={handleBackClick}
                            onEditStep={handleEditStepClick}
                            onSubmit={handleSubmitClick}
                            onStartOver={handleStartOverClick}
                        />
                    }
                    {/* Application was submitted and passed for lenders without issue */}
                    {flow && currentStepIndex === flow?.steps.length + 1 &&
                        <StatusStep
                            appInProgress={applicationInProgress.current}
                            message={
                                <div className='text-center w-100'>
                                <h3 className='text-uppercase text-condensed'>{context.translations.get('currency.thankyouforapplyingwithcurrency')}<img alt='' style={{ marginBottom: -5 }} src='images/coin.png' width={10} /></h3>

                                    {/* Submitted successfully, no additional work from the user required. */}
                                    {!applicationInProgress.current?.didNotPassAnyLenders && !applicationInProgress.current?.isCreditFrozen && (!applicationInProgress.current?.offers || applicationInProgress.current?.offers.length === 0) &&
                                        <>
                                            {/* Optional Work to upload stips if any */}
                                            {applicationInProgress.current?.hasStipulations &&
                                                <>
                                                    <span>
                                                        {context.translations.get('currency.repreachoutshortly')} <br />
                                                        {context.translations.get('currency.uploaddocsinmeantime')}
                                                    </span>
                                                    <br />
                                                    <Link to={`/application/${applicationInProgress.current?.applicationGUID}`} target='_blank'>
                                                        <Button size='sm' variant='secondary' className='mt-3 mb-2'>{context.translations.get('currency.uploaddocuments')}</Button>
                                                    </Link>
                                                </>
                                            }
                                            <AnimatedCheck className='color-gold' />
                                        </>
                                    }

                                    {/* Submitted successfully, got offers back from the lender that need displayed. */}
                                    {!applicationInProgress.current?.didNotPassAnyLenders && !applicationInProgress.current?.isCreditFrozen && applicationInProgress.current?.offers && applicationInProgress.current?.offers.length > 0 &&
                                        <>
                                            <span>{context.translations.get('currency.matchedwithoffers')}</span>
                                            <br />
                                            <OfferTable offers={applicationInProgress.current?.offers} />
                                        </>
                                    }

                                    {/* Submitted, but credit was 0. Ask them to unfreeze. */}
                                    {/* This does not need translated, as we don't currently pull credit for non US apps */}
                                    {applicationInProgress.current?.isCreditFrozen &&
                                        <>
                                            <span>
                                                We've detected a possible freeze on your credit profile. To provide you with financing options, please contact the credit bureau to
                                                unfreeze your credit profile. To manage your freeze, please click the button below or call Equifax at <a href='tel:888-298-0045'>888-298-0045</a>.
                                                A Currency representative will contact you to continue your application.
                                            </span>
                                            <br />
                                            <a target='_blank' href={applicationInProgress.current?.unfreezeCreditURL ?? 'https://my.equifax.com/consumer-registration/UCSC/#/personal-info'}>
                                                <Button className='my-3' size='lg' variant='outline-primary'>Unfreeze Credit <FaExternalLinkAlt /></Button>
                                            </a>
                                        </>
                                    }

                                    {/* Submitted, but didn't pass for any lenders. Redirect to FinMarket */}
                                    {applicationInProgress.current?.didNotPassAnyLenders && !applicationInProgress.current?.isCreditFrozen &&
                                        <>
                                            <p>
                                                {context.translations.get('currency.thankyouforapplyingnoplacement')}
                                                {applicationInProgress.current.finMarketRedirectURL &&
                                                    <>
                                                        &nbsp; {context.translations.get('currency.wewillredirectinseconds', (5 - redirectTimer <= 0 ? 0 : 5 - redirectTimer).toString())} <br />
                                                        <a style={{ textDecoration: 'underline' }} href={applicationInProgress.current.finMarketRedirectURL} target='_blank'>{context.translations.get('currency.clickifwindowdoesnotopen')}</a>
                                                    </>
                                                }
                                            </p>
                                            <AnimatedExclamation className='color-gold' />
                                        </>
                                    }

                                    <h4><b>{context.translations.get('currency.referencenumber', applicationInProgress.current?.applicationID?.toString() || '')}</b></h4>
                                </div>
                            }
                        />
                    }
                    <ProgressBar className='w-100 mt-2' label={loadingFlow ? null : (currentStepIndex > (flow?.steps.length || 1) ? '100%' : `${Math.ceil(((currentStepIndex) / (flow?.steps.length || 1)) * 100)}%`)} now={currentStepIndex + 1} min={1} max={(flow?.steps.length || 0) + 1} />
                </div>
            </div >
        </DataContext.Provider>
    );
}

export default Home;