import React, { useContext, useEffect } from 'react';
import { StepQuestion } from '../../models/StepQuestion';
import CurrencyUtility from '../../utilities/CurrencyUtility';
import { AppContext } from '../../AppContext';

interface Props {
    onChange: (value: number) => void,
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
    currencyCode?: string,
}

const NetToFinance = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);
    const { onChange, dependantQuestions = [], questionValues = {} }  = props;
    const totalCostOfEquipment = dependantQuestions?.find(x => x.name === "Total Cost of Equipment");
    const downPayment = dependantQuestions?.find(x => x.name === "Down Payment");
    const totalCostOfEquipmentValue = (totalCostOfEquipment && questionValues[totalCostOfEquipment.inputDefinitionID]) || undefined;
    const downPaymentValue = (downPayment && questionValues[downPayment.inputDefinitionID]) || 0;

    useEffect(() => {
        if (totalCostOfEquipmentValue !== undefined)
            onChange((totalCostOfEquipmentValue || 0) - (downPaymentValue || 0));
        // eslint-disable-next-line
    }, [totalCostOfEquipmentValue, downPaymentValue]);

    return (
        <div className='form-group form-label'>
            <label className='label form-label'>{ context.translations.get("labelNetToFinance") }:</label>
            <span className='float-right'>{CurrencyUtility.formatCurrency(totalCostOfEquipmentValue - downPaymentValue, undefined, props.currencyCode)}</span>
        </div>
    )
}

export default NetToFinance;