import React, { useContext, useEffect } from 'react';
import { AutocompleteInput, AutocompleteInputCallbackType } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import { FaSearch } from 'react-icons/fa';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string,
    disabled?: boolean,
    placeholder?: string,
    manufacturer?: string,
    categoryID?: number,
    value?: { value: any, label: string, isDisabled?: boolean } | null,
    onChange: (option: { value: any, label: string } | null) => void,
}

const ModelAutocomplete = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    useEffect(() => {
        if (!props.disabled && !props.value?.isDisabled && props.value !== null && props.value !== undefined)
            props.onChange(null);
    }, [props.manufacturer]); // Specifically left value out of dep array, because we don't want to clear the value when it changes

    const searchModels: AutocompleteInputCallbackType = (input, callback) => {
        if (!input || input.length < 2) {
            callback([]);
            return;
        }

        FlowProvider.SearchModels(context.vendorID, context.topLevelPartnerVendorID, input, context.languageID, props.manufacturer, props.categoryID).then((result) => {
            if (result) {
                callback(result.options);
            } else {
                callback([]);
            }
        });
    }

    // raw dog some of the props to the autocomplete input
    const rawProps = {
        components: {
            DropdownIndicator: () => <FaSearch style={{ marginRight: 10, marginLeft: 10 }} className='text-light-gray' />, // Override the arrow
            // IndicatorSeparator: () => null, // Without arrow, don't want the separator
        },
        noOptionsMessage: () => null, // No menu until there are options
    };


    return (
        <AutocompleteInput {...props} containerProps={{ className: 'label-colorize' }} rawAsyncSelectProps={rawProps} allowTypeIn typeInOptionLabel={context.translations.get('currency.confirmcustom')} disabled={props.disabled || !props.manufacturer || props.value?.isDisabled} clearable fetchOptions={searchModels} >
            {props.children}
        </AutocompleteInput>
    )
}

export default ModelAutocomplete;