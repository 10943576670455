import React, { useContext, useState } from 'react';
import { InputNote } from 'sandhills-react-bootstrap-ui';
import { AppContext } from '../../AppContext';
import Context from '../../models/client/Context';

interface Props {
    id: string,
    value?: boolean,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}

const DoosanTermsAndAgreementsCheck = (props: Props) => {
    const context: Context = useContext(AppContext);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

    const handleScroll = (e: React.UIEvent) => {
        // Check if the user has scrolled to the bottom of the div
        const element = e.target as HTMLDivElement;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
            setHasScrolledToBottom(true);
            props.onChange(true);
        }
    }

    return (
        <div>
            {!context.oemDealerType?.includes("Canadian") && !context.oemDealerType?.includes("Canada") ?
                <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                    <p>
                        {context.translations.get("newAppDisclosure1")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure2")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure3")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure4")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure5")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure6")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure7")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure8")}
                    </p>
                </div>
            :
                <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                    <p>
                        {context.translations.get("newAppCaDisclosure1")}
                    </p>
                </div>
            }
            <InputNote>{(!hasScrolledToBottom && props.className === 'input-error') && context.translations.get('currency.scrollthroughagreement')}&nbsp;</InputNote>
        </div>
    );
}

export default DoosanTermsAndAgreementsCheck;