import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string
}

const PatriotActDisplay = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div className='w-100 px-3 small'>
            <i>
                {context.translations.get("Currency.PatriotActExplanation")}
            </i>
        </div>
    );
}

export default PatriotActDisplay;