import React, { useContext } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import { AppContext } from '../../AppContext';

interface Props {
    value?: SelectOption | null,
    className?: string,
    disabled?: boolean,
    placeholder?: string,
    onChange: (value: { label: string, value: any } | null) => void
}

const MonthDropdown = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    const options = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => 
        ({ label: context.translations.get('currency.' + month), value: index + 1 }));

    return (
        <DropdownInput rawSelectProps={{ menuPosition: 'fixed' }} disabled={props.disabled} className={props.className} containerProps={{ className: 'label-colorize' }} placeholder={props.placeholder} searchable value={options.find(x => x.value == props.value?.value) ?? {label: '', value: ''}} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default MonthDropdown;