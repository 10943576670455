import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { TextInput, NumberInput, CheckInput, DropdownInput, RadioInput, InputLabel, CurrencyInput, InputNote, TextAreaInput } from 'sandhills-react-bootstrap-ui';
import CategoryInput from './controltypeinputs/CategoryInput';
import ButtonSpinner from './ButtonSpinner';
import LegalEntityInput from './controltypeinputs/LegalEntityInput';
import DealTypeInput from './controltypeinputs/DealTypeInput';
import EquipmentYearInput from './controltypeinputs/EquipmentYearInput';
import ModelAutocomplete from './controltypeinputs/ModelAutocomplete';
import ManufacturerAutocomplete from './controltypeinputs/ManufacturerAutocomplete';
import Context from '../models/client/Context';
import { StepQuestion } from '../models/StepQuestion';
import LoanAmountDisplay from './controltypeinputs/LoanAmountDisplay';
import YearInput from './controltypeinputs/YearInput';
import MonthDropdown from './controltypeinputs/MonthDropdown';
import OwnOrRentRadio from './controltypeinputs/OwnOrRentRadio';
import EmploymentStatusDropdown from './controltypeinputs/EmploymentStatusDropdown';
import OccupationDropdown from './controltypeinputs/OccupationDropdown';
import ImmigrationStatusDropdown from './controltypeinputs/ImmigrationStatusDropdown';
import MaritalStatusDropdown from './controltypeinputs/MaritalStatusDropdown';
import PhoneNumberTypeRadio from './controltypeinputs/PhoneNumberTypeRadio';
import BusinessStructureDropdown from './controltypeinputs/BusinessStructureDropdown';
import StateDropdown from './controltypeinputs/StateDropdown';
import CountryDropdown from './controltypeinputs/CountryDropdown';
import PhoneNumberInput from './controltypeinputs/PhoneNumberInput';
// import IndustryInput from './controltypeinputs/IndustryInput';
import Last4SSNInput from './controltypeinputs/Last4SSNInput';
import TermsAndAgreementsCheck from './controltypeinputs/TermsAndAgreementsCheck';
import DevelonTermsAndAgreementsCheck from './controltypeinputs/DevelonTermsAndAgreementsCheck';
import DoosanTermsAndAgreementsCheck from './controltypeinputs/DoosanTermsAndAgreementsCheck';
import SocialSecurityNumberInput from './controltypeinputs/SocialSecurityNumberInput';
import EINInput from './controltypeinputs/EINInput';
import JointGuarantorExplanationDisplay from './controltypeinputs/JointGuarantorExplanationDisplay';
import BirthdayInput from './controltypeinputs/BirthdayInput';
import { QuestionGroup } from '../models/QuestionGroup';
import PostalCodeInput from './controltypeinputs/PostalCodeInput';
import ImageDisplay from './controltypeinputs/ImageDisplay';
import PercentInput from './controltypeinputs/PercentInput';
import DoNotShareCheck from './controltypeinputs/DoNotShareCheck';
import IndustryDropdown from './controltypeinputs/IndustryDropdown';
import SocialInsuranceNumberInput from './controltypeinputs/SocialInsuranceNumberInput';
import { InputGroup } from 'react-bootstrap';
import ListingLinkInput from './controltypeinputs/ListingLinkInput';
import PatriotActDisplay from './controltypeinputs/PatriotActDisplay';
import AddressAutocompleteInput from './controltypeinputs/AddressAutocompleteInput';
import MarketingProgramDropdown from './controltypeinputs/MarketingProgramDropdown';
import NetToFinance from './controltypeinputs/NetToFinance';
import DevelonDealerDisclosure from './controltypeinputs/DevelonDealerDisclosure';
import OEMDealerDisclosure from './controltypeinputs/OEMDealerDisclosure';
import PricingLevelDropdown from './controltypeinputs/PricingLevelDropdown';
import SignatureInput from './controltypeinputs/SignatureInput';
import TermDropdown from './controltypeinputs/TermDropdown';
import OEMEquipmentType from './controltypeinputs/OEMEquipmentType';
import OEMModelDropdown from './controltypeinputs/OEMModelDropdown';
import TotalCostOfEquipment from './controltypeinputs/TotalCostOfEquipment';
import '../custom.scss';
import { SelectOption } from '../models/SelectOption';

interface Props {
    id: string,
    question: StepQuestion,
    group: QuestionGroup,
    disabled?: boolean,
    loading?: boolean,
    errorMessage?: string,
    allFlowQuestions: StepQuestion[],
    inputValues: { [inputDefinitionID: number]: any },
    questionValues: { [inputDefinitionID: number]: any },
    className?: string,
    onChange: (question: StepQuestion, value: any) => void,
}

interface WrapperProps {
    question: StepQuestion,
    loading?: boolean,
    hideLabel?: boolean,
}

const InputWrapper = (props: React.PropsWithChildren<WrapperProps>) => {
    const context: Context = useContext(AppContext);

    return (
        <div className='d-flex flex-column flex-wrap flex-md-nowrap label-colorize'>
            <InputLabel className='label' >
                {props.hideLabel !== true && 
                    <div className='mb-2'>
                        {context.translations.get(props.question.label)}
                        {(props.question.isRequired ? '*' : (props.question.controlType.toLowerCase() !== 'modelautocomplete' ? ` (${context.translations.get('Sandhills.CRM.DSCRM.Optional')})` : ''))}
                    </div>
                }
                {props.loading ? <ButtonSpinner /> : props.children}
            </InputLabel>
        </div>
    )
}

const InputSwitch = (props: Props) => {
    const context: Context = useContext(AppContext);

    let dependantQuestions: StepQuestion[] = [];
    if (props.question.dependantQuestions && props.question.dependantQuestions.length > 0) {
        dependantQuestions = props.allFlowQuestions.filter(x => props.question.dependantQuestions!.findIndex(y => y.inputDefinitionID === x.inputDefinitionID) > -1);
    }

    const handleChange = (value: any) => {
        props.onChange(props.question, value);
    }

    const handleChangeQuestion = (question: StepQuestion, value: any) => {
        props.onChange(question, value);
    }

    const renderNote = () => {
        if (props.errorMessage) {
            return <InputNote className='text-danger'>{props.errorMessage}</InputNote>;
        }

        return null;
    }

    const inputOptions = () => {
        return props.question.options ? props.question.options?.map(x => { return { ...x, label: context.translations.get(x.label) } as SelectOption; }) : [] as SelectOption[];
    }

    switch (props.question.controlType.toLowerCase()) {
        case "textinput":
        case "city":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <TextInput id={props.id} className={props.className} containerProps={{ className: 'label-colorize' }} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </TextInput>
                </InputWrapper>
            );
        case "textdisplay":
            const depQ = dependantQuestions[0];
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <TextInput id={props.id} className={props.className} containerProps={{ className: 'label-colorize' }} disabled={props.disabled} value={props.inputValues[depQ.inputDefinitionID]} >
                        {renderNote()}
                    </TextInput>
                </InputWrapper>
            );
        case "numberinput":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <NumberInput id={props.id} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </NumberInput>
                </InputWrapper>
            );
        case "percentinput":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <PercentInput disabled={props.disabled} className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} dependantQuestions={dependantQuestions} questionValues={props.questionValues}>
                        {renderNote()}
                    </PercentInput>
                </InputWrapper>
            );
        case "currencyinput":
            const currencyQ = dependantQuestions.find(x => x.name.toUpperCase() === "CURRENCY CODE");
            let currencyCodeForCurrency = context.defaultCurrencyCode;
            if (currencyQ)
                currencyCodeForCurrency = props.questionValues[currencyQ.inputDefinitionID];

            // Arbitrary max value of 9,999,999 (I think max loan is 1 million)
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <InputGroup className="mb-3 flex-row">
                        <CurrencyInput
                            id={props.id}
                            className={props.className}
                            disabled={props.disabled}
                            currencyCode={currencyCodeForCurrency}
                            max={999999999}
                            value={props.inputValues[props.question.inputDefinitionID]}
                            onChange={handleChange}
                            containerProps={{ className: 'd-flex flex-row flex-grow-1 mb-0' }}
                        />
                        <InputGroup.Text>{currencyCodeForCurrency}</InputGroup.Text>
                    </InputGroup>
                    {renderNote()}
                </InputWrapper>
            );
        case "downpaymentinput":
            const costForDPQ = dependantQuestions.find(x => x.name.toUpperCase() === "TOTAL COST OF THE EQUIPMENT");
            let maxForDownPayment = undefined;
            if (costForDPQ) {
                const dependantValue = props.questionValues[costForDPQ.inputDefinitionID];
                if (dependantValue && !isNaN(parseInt(dependantValue)))
                    maxForDownPayment = parseInt(dependantValue) - 500;
            }
            const currencyQDP = dependantQuestions.find(x => x.name.toUpperCase() === "CURRENCY CODE");
            let currencyCodeForDownPayment = context.defaultCurrencyCode;
            if (currencyQDP)
                currencyCodeForDownPayment = props.questionValues[currencyQDP.inputDefinitionID];

            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <InputGroup className="mb-3 flex-row">
                        <CurrencyInput
                            id={props.id}
                            disabled={props.disabled}
                            currencyCode={currencyCodeForDownPayment}
                            min={0}
                            max={maxForDownPayment}
                            value={props.inputValues[props.question.inputDefinitionID]}
                            onChange={handleChange}
                            containerProps={{ className: 'd-flex flex-row flex-grow-1 mb-0' }}
                        />
                        <InputGroup.Text>{currencyCodeForDownPayment}</InputGroup.Text>
                    </InputGroup>
                    {renderNote()}
                </InputWrapper>
            );
        case "dropdowninput":
            var options = inputOptions();
            var value = options.find((x: SelectOption) => String(x.value) === String(props.inputValues[props.question.inputDefinitionID]?.value)) ?? { label: '', value: ''};
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <DropdownInput id={props.id} clearable searchable placeholder='' className={props.className} containerProps={{ className: 'label-colorize' }} disabled={props.disabled} options={options} value={value} onChange={handleChange} >
                        {renderNote()}
                    </DropdownInput>
                </InputWrapper>
            );
        case "addressautocompleteinput":
            let countryIDForCountryRestrict = undefined;
            if (dependantQuestions.length > 0)
                countryIDForCountryRestrict = props.questionValues[dependantQuestions[0].inputDefinitionID];

            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <AddressAutocompleteInput className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} question={props.question} group={props.group} onChange={handleChangeQuestion}
                        countryIDForCountryRestrict={countryIDForCountryRestrict} placeholder={context.translations.get('Sandhills.EnterStreetAddress')}>
                        {renderNote()}
                    </AddressAutocompleteInput>
                </InputWrapper>
            )
        case "checkinput":
            return (
                <InputWrapper question={props.question} loading={props.loading} hideLabel>
                    <CheckInput id={props.id} label={context.translations.get(props.question.label)} className={`${props.className} checkbox-input`} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </CheckInput>
                </InputWrapper>
            );
        case "donotsharecheck":
            return (
                <InputWrapper question={props.question} loading={props.loading} hideLabel>
                    <DoNotShareCheck id={props.id} label={context.translations.get(props.question.label)} className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
                </InputWrapper>
            );
        case "birthday":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <BirthdayInput className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </BirthdayInput>
                </InputWrapper>
            );
        case "phonenumber":
            let countryIDForPhone = undefined;
            if (dependantQuestions.length > 0)
                countryIDForPhone = props.questionValues[dependantQuestions[0].inputDefinitionID];

            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <PhoneNumberInput countryID={countryIDForPhone} className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </PhoneNumberInput>
                </InputWrapper>
            );
        case "listinglink":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <ListingLinkInput className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} question={props.question} allFlowQuestions={props.allFlowQuestions} onChange={handleChangeQuestion} >
                        {renderNote()}
                    </ListingLinkInput>
                </InputWrapper>
            );
        case "categoryautocomplete":
            const makeQ = dependantQuestions.find(x => x.name.toUpperCase() === "EQUIPMENT MANUFACTURER");
            const modelQ = dependantQuestions.find(x => x.name.toUpperCase() === "EQUIPMENT MODEL");
            let makeForCategory;
            let modelForCategory;
            if (makeQ)
                makeForCategory = props.questionValues[makeQ.inputDefinitionID];
            if (modelQ)
                modelForCategory = props.questionValues[modelQ.inputDefinitionID];

            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <CategoryInput id={props.id} clearable placeholder={context.translations.get('currency.searchplaceholder')} manufacturer={makeForCategory} model={modelForCategory} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange}>
                        {renderNote()}
                    </CategoryInput>
                </InputWrapper>
            );
        case "legalentitydropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <LegalEntityInput id={props.id} clearable placeholder={context.translations.get('currency.selectplaceholder')} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </LegalEntityInput>
                </InputWrapper>
            );
        case "dealtypetoggle":
            return (
                <DealTypeInput value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
            );
        case "equipmentyeardropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <EquipmentYearInput id={props.id} clearable placeholder={context.translations.get('currency.selectplaceholder')} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </EquipmentYearInput>
                </InputWrapper>
            );
        case "manufacturerautocomplete":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <ManufacturerAutocomplete id={props.id} placeholder={context.translations.get('currency.searchplaceholder')} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </ManufacturerAutocomplete>
                </InputWrapper>
            );
        case "modelautocomplete":
            const manufacturerQ = dependantQuestions.find(x => x.name.toUpperCase() === "EQUIPMENT MANUFACTURER");
            const categoryQ = dependantQuestions.find(x => x.name.toUpperCase() === "EQUIPMENT CATEGORY");
            let makeForModel;
            let categoryForModel;
            if (manufacturerQ)
                makeForModel = props.questionValues[manufacturerQ.inputDefinitionID];
            if (categoryQ)
                categoryForModel = props.questionValues[categoryQ.inputDefinitionID];

            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <ModelAutocomplete id={props.id} placeholder={context.translations.get('currency.searchplaceholder')} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} manufacturer={makeForModel} categoryID={categoryForModel} >
                        {renderNote()}
                    </ModelAutocomplete>
                </InputWrapper>
            );
        case "loanamountdisplay":
            const costQuestion = dependantQuestions.find(x => x.name.toUpperCase() === "TOTAL COST OF THE EQUIPMENT");
            const downQuestion = dependantQuestions.find(x => x.name.toUpperCase() === "DOWN PAYMENT");
            const currencyCodeForLAQ = dependantQuestions.find(x => x.name.toUpperCase() === "CURRENCY CODE");
            let cost;
            let downPayment;
            let currencyCodeLA = context.defaultCurrencyCode;
            if (costQuestion)
                cost = props.questionValues[costQuestion.inputDefinitionID];
            if (downQuestion)
                downPayment = props.questionValues[downQuestion.inputDefinitionID];
            if (currencyCodeForLAQ)
                currencyCodeLA = props.questionValues[currencyCodeForLAQ.inputDefinitionID];
            return (
                <LoanAmountDisplay id={props.id} className={props.className} cost={cost} downPayment={downPayment} currencyCode={currencyCodeLA} onChange={handleChange} >
                    {renderNote()}
                </LoanAmountDisplay>
            );
        case "monthdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <MonthDropdown className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} placeholder={context.translations.get('currency.selectplaceholder')}>
                        {renderNote()}
                    </MonthDropdown>
                </InputWrapper>
            );
        case "yeardropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <YearInput id={props.id} clearable placeholder={context.translations.get('currency.selectplaceholder')} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange}>
                        {renderNote()}
                    </YearInput>
                </InputWrapper>
            );
        case "ownorrentradio":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <OwnOrRentRadio name={props.question.name} className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </OwnOrRentRadio>
                </InputWrapper>
            );
        case "phonenumbertyperadio":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <PhoneNumberTypeRadio id={props.id} name={props.question.name} className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange}>
                        {renderNote()}
                    </PhoneNumberTypeRadio>
                </InputWrapper>
            );
        case "employmentstatusdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <EmploymentStatusDropdown value={props.inputValues[props.question.inputDefinitionID]} placeholder={context.translations.get('currency.selectplaceholder')} className={props.className} onChange={handleChange}>
                        {renderNote()}
                    </EmploymentStatusDropdown>
                </InputWrapper>
            );
        case "businessstructuredropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <BusinessStructureDropdown className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange}>
                        {renderNote()}
                    </BusinessStructureDropdown>
                </InputWrapper>
            );
        case "occupationdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <OccupationDropdown value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange} placeholder={context.translations.get('currency.selectplaceholder')}>
                        {renderNote()}
                    </OccupationDropdown>
                </InputWrapper>
            );
        case "immigrationstatusdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <ImmigrationStatusDropdown value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange} placeholder={context.translations.get('currency.selectplaceholder')}>
                        {renderNote()}
                    </ImmigrationStatusDropdown>
                </InputWrapper>
            );
        case "maritalstatusdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <MaritalStatusDropdown value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange} placeholder={context.translations.get('currency.selectplaceholder')}>
                        {renderNote()}
                    </MaritalStatusDropdown>
                </InputWrapper>
            );
        case "postalcode":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <PostalCodeInput className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} question={props.question} group={props.group} onChange={handleChangeQuestion} allowChangingCountry={context.vendorSettings["AllowChangingCountry"]} questionValues={props.questionValues}>
                        {renderNote()}
                    </PostalCodeInput>
                </InputWrapper>
            );
        case "countrydropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <CountryDropdown value={props.inputValues[props.question.inputDefinitionID]} disabled={!context.vendorSettings["AllowChangingCountry"]} className={props.className} onChange={handleChange} dependantQuestions={dependantQuestions} questionValues={props.questionValues}>
                        {renderNote()}
                    </CountryDropdown>
                </InputWrapper>
            );
        case "statedropdown":
            let countryIDForState = undefined;
            if (dependantQuestions.length > 0)
                countryIDForState = props.questionValues[dependantQuestions[0].inputDefinitionID];

            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <StateDropdown value={props.inputValues[props.question.inputDefinitionID]} className={props.className} countryID={countryIDForState} onChange={handleChange} placeholder={context.translations.get('currency.selectplaceholder')}>
                        {renderNote()}
                    </StateDropdown>
                </InputWrapper>
            )
        case "industry":
        case "industrydropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <IndustryDropdown value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange}>
                        {renderNote()}
                    </IndustryDropdown>
                </InputWrapper>
            )
        case "last4ssn":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <Last4SSNInput value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange}>
                        {renderNote()}
                    </Last4SSNInput>
                </InputWrapper>
            );
        case "defaulttermsandagreementscheck":
            return (
                <TermsAndAgreementsCheck id={props.id} className={props.className} required={props.question.required} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
            )
        case "doosantermsandagreementscheck":
            return (
                <DoosanTermsAndAgreementsCheck id={props.id} className={props.className} required={props.question.required} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
            );
        case "develontermsandagreementscheck":
            return (
                <DevelonTermsAndAgreementsCheck id={props.id} className={props.className} required={props.question.required} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
            );
        case "ein":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <EINInput value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange}>
                        {renderNote()}
                    </EINInput>
                </InputWrapper>
            );
        case "socialsecuritynumber":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <SocialSecurityNumberInput value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange} >
                        {renderNote()}
                    </SocialSecurityNumberInput>
                </InputWrapper>
            );
        case "sin":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <SocialInsuranceNumberInput value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange} >
                        {renderNote()}
                    </SocialInsuranceNumberInput>
                </InputWrapper>
            );
        case "yesnoradio":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <RadioInput name={props.question.name} value={props.inputValues[props.question.inputDefinitionID]} className={props.className} onChange={handleChange} disabled={props.disabled}>
                        <RadioInput.Radio id={`${props.id}-yes`} label={context.translations.get('sandhills.crm.dscrm.yes')} value={true} />
                        <RadioInput.Radio id={`${props.id}-no`} label={context.translations.get('sandhills.crm.dscrm.no')} value={false} />
                        {renderNote()}
                    </RadioInput>
                </InputWrapper>
            );
        case "jointguarantorexplanationdisplay":
            return (
                <JointGuarantorExplanationDisplay />
            )
        case "imagedisplay":
            return (
                <ImageDisplay value={props.inputValues[props.question.inputDefinitionID]} />
            );
        case "patriotactdisplay":
            return (
                <PatriotActDisplay />
            )
        case "hidden":
            return null;
        case "marketingprogramdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <MarketingProgramDropdown value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} dependantQuestions={dependantQuestions} questionValues={props.questionValues}
                        className={props.className}>
                        {renderNote()}
                    </MarketingProgramDropdown>
                </InputWrapper>
            )
        case "nettofinance":
            return (
                <NetToFinance dependantQuestions={dependantQuestions} questionValues={props.questionValues} currencyCode={context.defaultCurrencyCode} onChange={handleChange} />
            )
        case "oemdealerdisclosure":
            return (
                <OEMDealerDisclosure id={props.id} className={props.className} required={props.question.required} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
            )
        case "develondealerdisclosure":
            return (
                <DevelonDealerDisclosure id={props.id} className={props.className} required={props.question.required} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} />
            )
        case "pricingleveldropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <PricingLevelDropdown value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} onChangeQuestion={handleChangeQuestion} dependantQuestions={dependantQuestions}
                        questionValues={props.questionValues} inputValues={props.inputValues} className={props.className}>
                        {renderNote()}
                    </PricingLevelDropdown>
                </InputWrapper>
            )
        case "signatureinput":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <SignatureInput className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} dependantQuestions={dependantQuestions} questionValues={props.questionValues}>
                        {renderNote()}
                    </SignatureInput>
                </InputWrapper>
            )
        case "termdropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <TermDropdown className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} dependantQuestions={dependantQuestions} questionValues={props.questionValues} >
                        {renderNote()}
                    </TermDropdown>
                </InputWrapper>

            )
        case "radioinput":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <RadioInput id={props.id} name={props.question.name} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                        {props.question.options?.map(option => {
                            return (
                                <RadioInput.Radio id={`${props.id}-${option.value}`} className='no-select' label={context.translations.get(option.label)} value={option.value} />
                            )
                        })}
                    </RadioInput>
                </InputWrapper>
            );
        case "textarea":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <TextAreaInput id={props.id} className={props.className} disabled={props.disabled} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} >
                        {renderNote()}
                    </TextAreaInput>
                </InputWrapper>
            );
        case "oemequipmenttype":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <OEMEquipmentType className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChangeQuestion} dependantQuestions={dependantQuestions}
                        question={props.question} placeholder={context.translations.get('currency.searchplaceholder')}>
                        {renderNote()}
                    </OEMEquipmentType>
                </InputWrapper>
            );
        case "oemmodeldropdown":
            return (
                <InputWrapper question={props.question} loading={props.loading}>
                    <OEMModelDropdown className={props.className} value={props.inputValues[props.question.inputDefinitionID]} onChange={handleChange} dependantQuestions={dependantQuestions} questionValues={props.questionValues}>
                        {renderNote()}
                    </OEMModelDropdown>
                </InputWrapper>
            );
        case "totalcostofequipment":
            return (
                <TotalCostOfEquipment dependantQuestions={dependantQuestions} questionValues={props.questionValues} inputValues={props.inputValues} onChange={handleChange} currencyCode={context.defaultCurrencyCode}>
                    {renderNote()}
                </TotalCostOfEquipment>
            );
        default:
            return (
                <TextInput disabled>
                    <InputLabel className='text-danger'>// TODO - {props.question.controlType}</InputLabel>
                </TextInput>
            );
    }
}

export default InputSwitch;