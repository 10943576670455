import React, { useContext } from 'react';
import { RadioInput } from 'sandhills-react-bootstrap-ui';
import { AppContext } from '../../AppContext';

interface Props {
    id: string,
    name: string,
    className?: string,
    value?: string | number | boolean | null | undefined,
    onChange: (value: string | number | boolean | null | undefined) => void
}

const PhoneNumberTypeRadio = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    return (
        <RadioInput name={props.name} className={props.className} value={props.value} onChange={props.onChange}>
            {props.children}
            <RadioInput.Radio id={`${props.id}-cell`} className='no-select' label={context.translations.get('currency.cellphone')} value='cell' />
            <RadioInput.Radio id={`${props.id}-home`} className='no-select' label={context.translations.get('currency.homephone')} value='home' />
        </RadioInput>
    )
}

export default PhoneNumberTypeRadio;