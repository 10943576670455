import React, { useContext } from 'react';
import { AppContext } from '../AppContext';

interface Props {

}

const Footer = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div className='no-print mb-3 d-flex justify-content-center align-items-center flex-column'>
            <p className='mt-3 mb-0 text-gray text-upper text-center'>{context.translations.get('currency.footercopyright', new Date().getFullYear().toString())}</p>
            <div style={{ textDecoration: 'none' }} className='font-weight-bold'>
                <a className='accessible-gray' href='https://www.gocurrency.com/privacy-policy/' target='_blank' rel="noreferrer">{context.translations.get('footerprivacy')}</a>
                <i className='mx-2' />
                <a className='accessible-gray' href='https://www.gocurrency.com/terms-of-use/' target='_blank' rel="noreferrer">{context.translations.get('footertermsofuse')}</a>
                <i className='mx-2' />
                <a className='accessible-gray' href='https://www.gocurrency.com/legal/' target='_blank' rel="noreferrer">{context.translations.get('footerlegal')}</a>
                <i className='mx-2' />
                <a className='accessible-gray' href={`mailto:${context.supportEmail}`}>{context.translations.get('footercustomercare')}</a>
            </div>
        </div>
    );
}

export default Footer;