import React, { useContext } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';

interface Props {

}

const NotFound = (props: Props) => {
    const context = useContext(AppContext);

    return (
        <div className='w-100 d-flex justify-content-center'>
            <div style={{ width: 800, maxWidth: '96%' }} className='mt-5'>
                <Card className='w-100'>
                    <Card.Header style={{ fontSize: 30 }} className='bg-white text-center text-condensed font-weight-bold text-uppercase text-dark-gray'>
                        {context.translations.get('currency.pagedoesnotexist')}
                    </Card.Header>
                    <Card.Body className='bg-tint'>
                        {context.translations.get('currency.clickbuttonredirecthome')}
                    </Card.Body>
                    <Card.Footer className='bg-white text-right'>
                        <Link to='/'>
                            <Button variant='primary'>{context.translations.get('sandhills.gohome')}</Button>
                        </Link>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    );
}

export default NotFound;