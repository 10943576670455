import React, { useContext, useEffect } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';
import { AppContext } from '../../AppContext';

interface Props {
    value?: SelectOption,
    className?: string,
    onChange: (value: { label: string, value: any } | null) => void,
    placeholder?: string
}

// const options = [
//     'Single',
//     'Married',
//     'Separated',
//     'Divorced',
//     'Widowed'
// ].map(month => ({ label: month, value: month }));

const MaritalStatusDropdown = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    const [options, setOptions] = React.useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetMaritalTypes(context.languageID).then((result) => {
            if (result)
                setOptions(result.options);
        });
    }, [context.languageID]);

    return (
        <DropdownInput searchable value={props.value} className={props.className} containerProps={{ className: 'label-colorize' }} onChange={props.onChange} options={options} placeholder={props.placeholder}>
            {props.children}
        </DropdownInput>
    )
}

export default MaritalStatusDropdown;