import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { AutocompleteInput, AutocompleteInputCallbackType } from 'sandhills-react-bootstrap-ui';
import { FormGroupProps } from 'react-bootstrap';
import FlowProvider from '../../providers/FlowProvider';
import { v4 as uuid } from 'uuid';

interface Props {
    id?: string,
    manufacturer?: string,
    model?: string,
    className?: string,
    searchable?: boolean,
    clearable?: boolean,
    value?: { value: any, label: string, isDisabled?: boolean } | null,
    placeholder?: string,
    name?: string,
    disabled?: boolean,
    containerProps?: FormGroupProps,
    onChange: (option: { value: any, label: string } | null) => void,
}

const CategoryInput = (props: React.PropsWithChildren<Props>) => {

    const context = useContext(AppContext);

    // Key shenanigans force the select to re-fetch options
    // Just make the key a combo of manufacturer and model???
    const [key, setKey] = useState<string>(uuid());
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!props.disabled && !props.value?.isDisabled)
            props.onChange(null);
        setKey(uuid());
    }, [props.manufacturer, props.model]);

    // Get our list of allowed base categories
    const fetchAutocompleteOptions: AutocompleteInputCallbackType = (input, callback) => {
        // Can't limit input here, need to get solely based off of make / model some times
        // if (!input || input.length < 3) {
        //     callback([]);
        //     return;
        // }

        // Don't want to accidentally get all categories
        if (!props.manufacturer && !props.model && !input) {
            callback([]);
            return;
        }

        FlowProvider.SearchCategoryOptions(context.vendorID, context.topLevelPartnerVendorID, props.manufacturer, props.model, input, context.languageID).then((result) => {
            if (result) {
                callback(result.options);

                if (result.selectedIndex !== null && result.selectedIndex !== undefined && result.selectedIndex > -1 && !props.value?.isDisabled) {
                    props.onChange(result.options[result.selectedIndex]);
                }
            } else {
                callback([]);
            }
        });
    }

    const handleChange = (option: { value: any, label: string } | null) => {
        setMenuIsOpen(false);
        props.onChange(option);
    }

    // raw dog some of the props to the autocomplete input
    const rawProps = {
        noOptionsMessage: () => null, // No menu until there are options
        defaultOptions: true, // This has to be true to fire the callback when key changes
        menuIsOpen
    };

    return (
        // Special case where we disable the input if the value is disabled
        <AutocompleteInput key={key} {...props} onChange={handleChange} onFocus={() => setMenuIsOpen(true)} onBlur={() => setMenuIsOpen(false)} rawAsyncSelectProps={rawProps} disabled={props.disabled || !props.manufacturer || props.value?.isDisabled} fetchOptions={fetchAutocompleteOptions}>
            {props.children}
        </AutocompleteInput>
    )
}

export default CategoryInput;