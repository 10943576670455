import React, { useState, useContext } from 'react';
import CookiesPolicyModal from './CookiesPolicyModal'
import { CloseButton } from 'react-bootstrap';
import { AppContext } from '../../AppContext';

const CookiesBanner = () => {
    const context = useContext(AppContext);
    const [bannerOpen, setBannerOpen] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const closeBanner = () => {
        setBannerOpen(false);
    };

    const openModal = () => {
        setModalOpen(!modalOpen);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    return (
        <>
            {context.vendorSettings["ShowCookieBanner"] &&
                <div>
                    <CookiesPolicyModal title='Cookie Policy' show={modalOpen} onHide={closeModal} />
                    {bannerOpen && (
                        <div className="card fixed-bottom rounded shadow mb-3 d-flex d-block m-auto" style={{ backgroundColor: '#343A40', zIndex: 3, width: 1250, bottom: 5, maxWidth: 'calc(100% - 40px)' }}>
                            <CloseButton onClick={closeBanner} className="position-absolute" variant="white" style={{ fontSize: 12, right: 7, top: 6 }} />
                            <div className="card-body text-center small text-white">
                                {context.translations.getComponentFormat("Currency.CookieConsentMessage", {
                                    CookiesPolicy: <span onClick={openModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{context.translations.get('sandhills.cookiepolicy')}</span>,
                                    PrivacyPolicy: <a href="https://www.gocurrency.com/privacy-policy/" target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer', textDecoration: 'underline' }} className='text-white'>{context.translations.get('Sandhills.TradePubWebSite.MVC.PrivacyPolicy')}</a>
                                })}

                            </div>
                        </div>
                    )}
                </div>
            }
        </>
    );
}

export default CookiesBanner;