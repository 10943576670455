import React, { useContext, useEffect, useState } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { FormGroupProps } from 'react-bootstrap';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string,
    searchable?: boolean,
    clearable?: boolean,
    value?: { value: any, label: string } | null,
    placeholder?: string,
    name?: string,
    disabled?: boolean,
    containerProps?: FormGroupProps,
    onChange: (option: { value: any, label: string } | null) => void,
}

const LegalEntityInput = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);

    const [entityTypes, setEntityTypes] = useState<SelectOption[]>([]);

    useEffect(() => {
        getLegalEntityTypes();
    }, [context.languageID]);

    // Get our list of allowed base categories
    const getLegalEntityTypes = () => {
        FlowProvider.GetLegalEntityTypes(context.languageID).then((results) => {
            if (results) {
                setEntityTypes(results.options);
            }
        });
    }

    return (
        <DropdownInput {...props} options={entityTypes} >
            {props.children}
        </DropdownInput>
    )
}

export default LegalEntityInput;