import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../AppContext';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import { SelectOption } from '../../models/SelectOption';
import { StepQuestion } from '../../models/StepQuestion';
import { OEMVendorMapping } from '../../models/OEMVendorMapping';
import Context from '../../models/client/Context';

interface Props {
    value?: SelectOption,
    className?: string,
    onChange: (value: { label: string, value: any } | null) => void,
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
}

const OEMModelDropdown = (props: React.PropsWithChildren<Props>) => {
    const context: Context = useContext(AppContext);
    const [equipmentTypeToModelMappings, setEquipmentTypeToModelMappings] = useState<any>(null);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const equipmentTypeQuestion = props?.dependantQuestions?.find(x => x.modelPropertyName?.includes('EquipmentCode'));
    const equipmentTypeCode = (equipmentTypeQuestion && props?.questionValues && props.questionValues[equipmentTypeQuestion.inputDefinitionID]) || undefined;
    useEffect(() => {
        FlowProvider.GetEquipmentTypeToModelMapping(context.vendorID).then((result: OEMVendorMapping | null | undefined) => {
            if (result)
                setEquipmentTypeToModelMappings(result.equipmentTypeToModelMapping);
        });
    }, [context.vendorID]);

    useEffect(() => {
        if (equipmentTypeToModelMappings)
        {
            var newOptions: SelectOption[] = equipmentTypeToModelMappings[equipmentTypeCode].map((x: string) => { return { label: x, value: x, isDisabled: false } })
            setOptions(equipmentTypeToModelMappings[equipmentTypeCode].map((x: string) => { return { label: x, value: x, isDisabled: false } }));

            if (props.value !== null && props.value !== undefined && newOptions.findIndex(x => x.value === props.value?.value) === -1)
            {
                props.onChange(null);
            }
        }
        else if (props.value !== null && props.value !== undefined)
        {
            props.onChange(null);
        }
    }, [equipmentTypeCode]);

    return (
        <DropdownInput searchable className={props.className} containerProps={{ className: 'label-colorize' }} value={props.value} onChange={props.onChange} options={options}>
            {props.children}
        </DropdownInput>
    )
}

export default OEMModelDropdown;