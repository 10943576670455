import React, { useContext, useState } from 'react';
import { CheckInput, InputNote } from 'sandhills-react-bootstrap-ui';
import { AppContext } from '../../AppContext';

interface Props {
    id: string,
    value?: boolean,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}

const TermsAndAgreementsCheck = (props: Props) => {
    const context = useContext(AppContext);
    
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const PrivacyPolicy = <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/privacy-policy" target="_blank" rel="noreferrer">{context.translations.get("PrivacyPolicy")}</a>;
    const LegalDisclaimer = <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/legal" target="_blank" rel="noreferrer">{context.translations.get("legaldisclaimer")}</a>;
    const TermsOfUse = <a style={{ textDecoration: 'underline' }} href="https://www.gocurrency.com/terms-of-use" target="_blank" rel="noreferrer">{context.translations.get("footertermsofuse")}</a>;

    const handleScroll = (e: React.UIEvent) => {
        // Check if the user has scrolled to the bottom of the div
        const element = e.target as HTMLDivElement;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
            setHasScrolledToBottom(true);
        }
    } 

    return (
        <div>
            <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                {context.translations.get("Currency.TermsAndConditions1")}
                <ul className='checkmarks'>
                    <li>
                        {context.translations.getComponentFormat("Currency.TermsAndConditions2", { PrivacyPolicy, LegalDisclaimer, TermsOfUse })}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions3")}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions4")}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions5")}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions6")}

                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions7")}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions8")}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions9")}
                    </li>
                    <li>
                        {context.translations.get("Currency.TermsAndConditions10")}
                    </li>
                    <li>
                        {context.translations.getComponentFormat("Currency.TermsAndConditions11", { TermsOfUse })}
                    </li>
                </ul>
            </div>
            <CheckInput id={props.id} disabled={!hasScrolledToBottom} className={`mr-2 ${props.className}`} value={props.value} onChange={props.onChange} label={`${context.translations.get('currency.ihavereadandconsent')}${props.required ? '*' : ''}`}>
                <InputNote>{!hasScrolledToBottom && context.translations.get('currency.scrollthroughagreement')}&nbsp;</InputNote>
            </CheckInput>
        </div>
    );
}

export default TermsAndAgreementsCheck;