import React, { useContext, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Select, { components, StylesConfig } from 'react-select';
import { AppContext } from '../AppContext';
import { SelectOption } from '../models/SelectOption';

interface Props {
    className?: string,
    value?: SelectOption | null,
    smallScreen?: boolean, // hacky fix for mobile
    onChange?: (value: SelectOption | null) => void
}

const LocaleDropdownOption = (props: any) => {
    const { Option } = components;

    return (
        <Option {...props}>
            <ReactCountryFlag className='mr-1' countryCode={props.data.countryCode} svg />
            {props.data.label}
        </Option>
    );
}

const LocaleDropdownValue = (props: any) => {
    const { SingleValue } = components;

    return (
        <SingleValue {...props}>
            <ReactCountryFlag className='mr-1' countryCode={props.data.countryCode} svg />
            {/* <span className='d-none d-xl-inline-block'>{props.data.label}</span> */}
        </SingleValue>
    );
}

const CultureCodeDropdown = (props: Props) => {
    const context = useContext(AppContext);

    const [selectedLocale, setSelectedLocale] = useState<SelectOption | null>(props.value || null);


    useEffect(() => {
        setSelectedLocale(context.supportedCultureCodeOptions.find(x => x.value === context.cultureCode) || null);
    }, [context.cultureCode, context.supportedCultureCodeOptions]);

    const StyleOverrides: StylesConfig<any, false, any> = {
        container: (base, p) => ({
            ...base,
            width: 70,
            height: '30px',
            minHeight: '30px',
            maxHeight: '30px',
            border: 'none',
        }),
        control: (base, p) => ({
            ...base,
            border: 'none',
            cursor: 'pointer',
            height: '30px',
            minHeight: '30px',
            maxHeight: '30px',
            fontSize: '14px',
        }),
        input: (base, p) => ({
            ...base,
            height: '22px',
            minHeight: '22px',
            maxHeight: '22px',
            display: 'inline-block'
        }),
        valueContainer: (base, p) => ({
            ...base,
            height: '30px',
            minHeight: '30px',
            maxHeight: '30px',
            marginTop: 0,
            paddingTop: 0,
        }),
        indicatorsContainer: (base, p) => ({
            ...base,
            height: '30px',
            minHeight: '30px',
            maxHeight: '30px',
        }),
        menu: (base, p) => ({
            ...base,
            width: 220,
            position: props.smallScreen ? 'absolute' : undefined,
            right: props.smallScreen ? 0 : undefined,
        }),
    };

    return (
        <Select className={props.className} value={selectedLocale} styles={StyleOverrides} onChange={props.onChange} isSearchable={false} options={context.supportedCultureCodeOptions} components={{ Option: LocaleDropdownOption, SingleValue: LocaleDropdownValue }} />
    );
}

export default CultureCodeDropdown;