import React from 'react';
import { TextInput } from 'sandhills-react-bootstrap-ui';

interface Props {
    value?: string,
    secure?: boolean,
    className?: string,
    placeholder?: string,
    onChange: (value: string) => void,
    onEnter?: () => void
}

const SocialInsuranceNumberInput = (props: React.PropsWithChildren<Props>) => {

    const handleChange = (value: string) => {
        const cleaned = value.replace(/\D/g, '');
        
        // Format as SSN as you type in the format XXX XXX XXX
        const newVal = cleaned.length > 3 && cleaned.length < 7
            ? `${cleaned.slice(0, 3)} ${cleaned.slice(3)}`
            : cleaned.length >= 7
                ? `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`
                : cleaned;

        props.onChange(newVal);
    }

    return (
        <TextInput maxLength={11} secure={props.secure} placeholder={props.placeholder} className={props.className} value={props.value} onEnter={props.onEnter} onChange={handleChange}>
            {props.children}
        </TextInput>
    );
}

export default SocialInsuranceNumberInput;