import React, { useEffect, useState, useContext } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import { SelectOption } from '../../models/SelectOption';
import { StepQuestion } from '../../models/StepQuestion';
import { DataContext } from '../DataContext';

interface Props {
    value?: SelectOption,
    onChange: (value: { label: string, value: any } | null) => void,
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
    className?: string,
}

const MarketingProgramDropdown = (props: React.PropsWithChildren<Props>) => {
    const dataContext = useContext(DataContext);
    const { onChange, dependantQuestions = [], questionValues = {}, value } = props;
    const [options, setOptions] = useState<SelectOption[]>([]);
    const loanTypeQ = dependantQuestions.find(x => x.name.toUpperCase() === "CANADA DESIRED STRUCTURE");
    let loanType;
    if (loanTypeQ)
        loanType = questionValues[loanTypeQ.inputDefinitionID];
    const isLease = "LEASE" === loanType ? true : false;

    useEffect(() => {
        if (!dataContext.marketingPrograms) {
            FlowProvider.GetMarketingPrograms()
                .then((result) => {
                    if (result)
                        dataContext.setMarketingPrograms(result.marketingPrograms);
                });
        }
    }, []);

    useEffect(() => {
        if (dataContext.marketingPrograms) {
            const newOptions = Object.keys(dataContext.marketingPrograms[isLease ? 'Lease' : 'Loan / Finance Lease']).map((x: string) => ({ label: x, value: x, isDisabled: false }));
            setOptions(newOptions);

            if (value !== null && value !== undefined && newOptions.findIndex(x => x.value === value?.value) === -1) {
                onChange(null);
            }
        }
        // eslint-disable-next-line
    }, [dataContext.marketingPrograms, isLease]);

    return (
        <div>
            <DropdownInput clearable searchable value={props.value} onChange={props.onChange} options={options} className={props.className}>
                {props.children}
            </DropdownInput>
        </div>
    );
}

export default MarketingProgramDropdown;