import React, { useContext, useEffect, useState } from 'react';
import { DropdownInput, InputLabel } from 'sandhills-react-bootstrap-ui';
import FlowProvider from '../../providers/FlowProvider';
import { SelectOption } from '../../models/SelectOption';
import { StepQuestion } from '../../models/StepQuestion';
import { AppContext } from '../../AppContext';
import { DataContext } from '../../shared/DataContext';

interface Props {
    value?: SelectOption,
    onChange: (value: { label: string, value: any } | null) => void,
    onChangeQuestion: (question: StepQuestion, value: any) => void,
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
    inputValues?: { [inputDefinitionID: number]: any },
    className?: string,
}

const PricingLevelDropdown = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);
    const dataContext = useContext(DataContext);
    const { questionValues = {}, dependantQuestions = [], inputValues = {}, value, onChange } = props;

    const [options, setOptions] = useState<SelectOption[]>([]);
    const loanTypeQ = dependantQuestions.find(x => x.name.toUpperCase() === "CANADA DESIRED STRUCTURE");
    let loanType;
    if (loanTypeQ)
        loanType = questionValues[loanTypeQ.inputDefinitionID];
    const isLease = "LEASE" === loanType ? true : false;
    const marketingProgramQ = dependantQuestions.find(x => x.name.toUpperCase() === "MARKETING PROGRAM");
    let marketingProgram = "";
    if (marketingProgramQ)
        marketingProgram = questionValues[marketingProgramQ.inputDefinitionID];

    const eqTypeQ = dependantQuestions.find(x => x.name.includes("OEM Equipment Type"));
    const eqMakeQ = dependantQuestions.find(x => x.name.includes("Equipment Manufacturer") && x.name.includes("Type In"));
    const eqMakeDisplayQ = dependantQuestions.find(x => x.name.includes("Equipment Manufacturer") && x.name.includes("Display"));
    const eqModelQ = dependantQuestions.find(x => x.name.includes("Equipment Model"));
    const eqYearQ = dependantQuestions.find(x => x.name.includes("Equipment Year"));
    const eqTypeValue = (eqTypeQ && inputValues[eqTypeQ.inputDefinitionID]?.label) || undefined;
    const eqMakeValue = (eqMakeQ && questionValues[eqMakeQ.inputDefinitionID]) || (eqMakeDisplayQ && questionValues[eqMakeDisplayQ.inputDefinitionID]) || undefined;
    const eqModelValue = (eqModelQ && questionValues[eqModelQ.inputDefinitionID]) || undefined;
    const eqYearValue = (eqYearQ && questionValues[eqYearQ.inputDefinitionID]) || undefined;

    useEffect(() => {
        if (!dataContext.marketingPrograms) {
            FlowProvider.GetMarketingPrograms()
                .then((result) => {
                    if (result)
                        dataContext.setMarketingPrograms(result.marketingPrograms);
                });
        }
    }, []);

    useEffect(() => {
        if (marketingProgram) {
            const programs = Object.keys(dataContext.marketingPrograms[isLease ? 'Lease' : 'Loan / Finance Lease']);
            let newOptions: SelectOption[] = [];
            if (programs.includes(marketingProgram))
                newOptions = dataContext.marketingPrograms[isLease ? 'Lease' : 'Loan / Finance Lease'][marketingProgram].map((x: string) => ({ label: x, value: x, isDisabled: false }));
            setOptions(newOptions);

            if (value !== null && value !== undefined && newOptions.findIndex(x => x.value === value?.value) === -1)
                props.onChange(null);
        } else if (value !== null && value !== undefined)
            onChange(null);
        // eslint-disable-next-line
    }, [marketingProgram, isLease]);

    return (
        <>
            {eqTypeQ?.name === 'OEM Equipment Type 1' &&
                < div className="form-group no-gutters">
                    <div className="d-inline-block col-8 label">{context.translations.get("Sandhills.Items")}</div>
                    <div className="d-inline-block col-4 label">{context.translations.get("labelPricingLevel")}</div>
                </div >
            }
            <DropdownInput clearable searchable value={props.value} onChange={props.onChange} options={options} className={`float-right col-4 ${props.className}`} containerProps={{ className: 'no-gutters' }}>
                <InputLabel className="col-8">{eqTypeValue} - {eqYearValue} {context.translations.get(eqMakeValue) || 'Bobcat'} {eqModelValue} </InputLabel>
            </DropdownInput>
        </>
    );
}

export default PricingLevelDropdown;