import React, { useState } from 'react';
import { TextInput, InputNote } from 'sandhills-react-bootstrap-ui';
import { StepQuestion } from '../../models/StepQuestion';

interface Props {
    value?: number,
    className?: string,
    disabled?: boolean,
    onChange: (value?: number) => void,
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
}

const PercentInput = (props: React.PropsWithChildren<Props>) => {
    const [numericValue, setNumericValue] = useState(props.value); // This is necessary to allow the component to be unconrolled
    const [displayValue, setDisplayValue] = useState(props.value ? props.value + '%' : '');
    const [error, setError] = useState('');
    const questionValues = props.questionValues || {};

    const handleChange = (value: string) => {
        const cleaned = value.replace(/\D/g, '');
        setError('');
        if (cleaned === null || cleaned === '' || cleaned === undefined) {
            setDisplayValue('');
            setNumericValue(undefined);
            props.onChange(undefined);
            return;
        }

        let dependentPecent = 0;
        props.dependantQuestions?.forEach(question => {
            dependentPecent += questionValues[question.inputDefinitionID] || 0;
        });

        // Anything over 100 is invalid
        let newVal = Number(cleaned);
        if (newVal < 0 || newVal > 100 - dependentPecent) {
            setError(`Value must be between 0 and ${100 - dependentPecent}`);
            return;
        }

        setNumericValue(newVal);
        setDisplayValue(newVal.toString());

        props.onChange(newVal);
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (numericValue)
            setDisplayValue(numericValue.toString());
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (numericValue)
            setDisplayValue(numericValue + '%');
    }

    return (
        <TextInput disabled={props.disabled} maxLength={4} className={props.className + error ? ' input-error' : ''} value={displayValue} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChange}>
            {error && <InputNote className='text-danger'>{error}&nbsp;</InputNote>}
            {props.children}
        </TextInput>
    );
}

export default PercentInput;