import React, { useContext, useState } from 'react';
import { CheckInput, InputNote } from 'sandhills-react-bootstrap-ui';
import { AppContext } from '../../AppContext';
import Context from '../../models/client/Context';

interface Props {
    id: string,
    value?: boolean,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}

const DevelonDealerDisclosure = (props: Props) => {
    const context: Context = useContext(AppContext);

    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

    const handleScroll = (e: React.UIEvent) => {
        // Check if the user has scrolled to the bottom of the div
        const element = e.target as HTMLDivElement;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
            setHasScrolledToBottom(true);
        }
    }

    return (
        <div>
            {!context.oemDealerType?.includes("Canadian") && !context.oemDealerType?.includes("Canada") ?
                <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                    <p>
                        {context.translations.get("Sandhills.DevelonDealerDisclosure1")}
                    </p>
                    <p>
                        {context.translations.get("Sandhills.DevelonDealerDisclosure2")}
                    </p>
                    <p>
                        {context.translations.get("Sandhills.DevelonDealerDisclosure3")} 
                    </p>
                </div>
                :
                <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                    NA
                </div>
            }
            <CheckInput id={props.id} disabled={!hasScrolledToBottom} className={`mr-2 ${props.className}`} value={props.value} onChange={props.onChange} label={`${context.translations.get("labelNewAppConsent")}${props.required ? '*' : ''}`} >
                <InputNote>{!hasScrolledToBottom && context.translations.get("currency.scrollthroughagreement")}&nbsp;</InputNote>
            </CheckInput>
        </div>
    );
}

export default DevelonDealerDisclosure;
