import React, { useContext, useState } from 'react';
import { InputLabel, CheckInput, TextInput, InputNote } from 'sandhills-react-bootstrap-ui';
import { StepQuestion } from '../../models/StepQuestion';
import { AppContext } from '../../AppContext';

interface Props {
    value?: string,
    onChange: (value: string) => void,
    dependantQuestions?: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
    className?: string,
}

const SignatureInput = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);
    const [checked, setChecked] = useState<boolean>(false);
    const [text, setText] = useState<string>(props.value || "");
    const questionValues = props.questionValues || {};
    const dependantQuestions = props.dependantQuestions || [];
    const handleCheck = (value: boolean) => {
        setChecked(value);
        if (value) {
            props.onChange(text);
        }
    }
    const updateText = (value: string) => {
        setText(value);
        if (checked) {
            props.onChange(value);
        }
    };

    let firstName = questionValues[dependantQuestions.find(x => x.name.toUpperCase() === "FIRST NAME")?.inputDefinitionID || -1] ||
        questionValues[dependantQuestions.find(x => x.name.toUpperCase() === "BUSINESS CONTACT FIRST NAME")?.inputDefinitionID || -1] ||
        questionValues[dependantQuestions.find(x => x.name.toUpperCase() === "SECONDARY FIRST NAME")?.inputDefinitionID || -1];
    let lastName = questionValues[dependantQuestions.find(x => x.name.toUpperCase() === "LAST NAME")?.inputDefinitionID || -1] ||
        questionValues[dependantQuestions.find(x => x.name.toUpperCase() === "BUSINESS CONTACT LAST NAME")?.inputDefinitionID || -1] ||
        questionValues[dependantQuestions.find(x => x.name.toUpperCase() === "SECONDARY LAST NAME")?.inputDefinitionID || -1];

    return (
        <div>
            <h5><i>{firstName} {lastName}</i>:</h5>
            <div>
                <CheckInput className={'mr-2'} id={"signaturecheck"} value={checked} onChange={handleCheck}
                    label={ context.translations.get("borrowerdisclosureaccepted") } />
            </div>
            {(!checked && props.className === 'input-error') &&
                <InputNote className='text-danger'>{ context.translations.get("requiredvalidation") }</InputNote>
            }
            <div className='d-flex flex-column flex-wrap flex-md-nowrap label-colorize'>
                <InputLabel>{context.translations.get("borrowerdisclosuresignaturetext")}
                    <TextInput value={text} onChange={updateText} className={props.className}>
                        {props.children}
                    </TextInput>
                </InputLabel>
            </div>
        </div>
    );
}

export default SignatureInput;