
import React, { useContext, useEffect, useState } from "react";
import { GenericModal } from 'sandhills-react-bootstrap-ui';
import { Button, Col, Row } from "react-bootstrap";
import { AppContext } from "../AppContext";
import BrowserSelect from "../shared/controltypeinputs/BrowserSelect";
import ChromeFaq from "../shared/ChromeFaq";
import EdgeFaq from "../shared/EdgeFaq";
import { SelectOption } from "../models/SelectOption";
import { CultureSelectOption } from "../models/CultureSelectOption";
import CultureCodeDropdown from "../shared/CultureCodeDropdown";

const Header = () => {
    const context = useContext(AppContext);

    const [smallScreen, setSmallScreen] = useState<boolean>(window.innerWidth < 768);
    const [faqModalVisible, setFaqModalVisible] = useState<boolean>(false);
    const [faqIsChrome, setFaqIsChrome] = useState<boolean>(true);
    const [selectedCultureCode, setSelectedCultureCode] = useState<CultureSelectOption | null>(context.supportedCultureCodeOptions.find(x => x.value === context.cultureCode) ?? null);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setSmallScreen(window.innerWidth < 768);
        });
    }, []);

    const faqContent = () => {
        return (
            <div>
                <i>{context.translations.get('Currency.FAQCommonIssues')}</i><br /><br />

                <Col>
                    <Row>
                        <p>{context.translations.get('Currency.FAQBrowserSelect')}</p>
                        <BrowserSelect browserName='Chrome' browserIconPath='images/dbcs_faq/chrome-logo.png' className='ml-2' onClick={() => { setFaqIsChrome(true); }} active={faqIsChrome} />
                        <BrowserSelect browserName='Edge' browserIconPath='images/dbcs_faq/edge-logo.png' className='ml-2' onClick={() => { setFaqIsChrome(false); }} active={!faqIsChrome} />
                    </Row>
                </Col>
                <hr />
                <br />
                {faqIsChrome ?
                    <ChromeFaq /> : <EdgeFaq />
                }

            </div>
        );
    }

    const faqButtons = () => {
        return (
            <Button onClick={() => { setFaqModalVisible(false); }}>
                {context.translations.get('modalclose')}
            </Button>
        );
    }

    const handleCultureCodeChange = (option: SelectOption | null) => {
        if (!option) return;

        var newCultureCode: CultureSelectOption | null = context.supportedCultureCodeOptions.find(x => x.value === option.value) || null;
        setSelectedCultureCode(newCultureCode);
        context.setCultureCode(newCultureCode?.value);
        context.setLanguageID(newCultureCode?.languageID);
    }

    return (
        <>
            <div style={{ maxHeight: smallScreen ? '50px' : '70px', display: 'flex', justifyContent: 'space-around', backgroundColor: 'white' }} className='w-100 header shadow align-items-center'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: smallScreen ? '50px' : '70px', padding: '10px' }} className='img-div'>
                    <img alt='Currency Finance' style={{ height: '100%' }} src={context.logoURL ?? 'images/currency_finance.png'} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }} className='header-items'>
                    {context.vendorSettings["ShowLanguageSelector"] &&
                        <CultureCodeDropdown smallScreen={smallScreen} value={selectedCultureCode} onChange={handleCultureCodeChange} className={`mr-2`} />
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} className='header-items'>
                        <span className='item accessible-gray'>{context.translations.get('Sandhills.TradePubWebSite.MVC.Questions?')}</span>
                        {context.vendorSettings["ShowFaqButton"] ?
                            <Button onClick={(faqModal) => { setFaqModalVisible(true) }} variant='link' className='text-button'>{context.translations.get('Sandhills.FAQ')}</Button> :
                            <a href={`tel:${context.phoneNumber}`} style={{ fontSize: smallScreen ? 16 : 20 }} className='text-primary font-weight-bold hover'>{context.phoneNumber}</a>
                        }
                    </div>
                </div>

            </div>

            <GenericModal show={faqModalVisible} size='lg' onHide={() => { setFaqModalVisible(false) }} title={context.translations.get('Currency.FAQHeader')} renderContent={faqContent} renderButtons={faqButtons} />
        </>
    );
}

export default Header;