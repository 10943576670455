import React from 'react';
import { TextInput } from 'sandhills-react-bootstrap-ui';
import { QuestionGroup } from '../../models/QuestionGroup';
import { StepQuestion } from '../../models/StepQuestion';
import FlowProvider from '../../providers/FlowProvider';

interface Props {
    value?: string,
    question: StepQuestion,
    group: QuestionGroup,
    disabled?: boolean,
    className?: string,
    onChange: (question: StepQuestion, value: any) => void,
    allowChangingCountry?: boolean,
    questionValues: { [inputDefinitionID: number]: any },
}

const PostalCodeInput = (props: React.PropsWithChildren<Props>) => {

    const handleChange = (value: string) => {
        props.onChange(props.question, value);
        // For now, limit to search for more than 5 characters (US, Canada)
        if (value && value.length >= 5 && props.group.questions.length > 1) {
            // Get the state, city and county from the postal code
            FlowProvider.GetPostalCodeInformation(value).then((result) => {
                if (result) {
                    const countryQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'countrydropdown');
                    if (countryQuestion && result.countryID && props.allowChangingCountry) {
                        props.onChange(countryQuestion, { label: result.countryName, value: result.countryID });
                    }

                    const stateQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'statedropdown');
                    if (stateQuestion && result.state && (props.allowChangingCountry || (countryQuestion && props.questionValues[countryQuestion.inputDefinitionID] === result.countryID ))) {
                        props.onChange(stateQuestion, { label: result.state, value: result.stateID });
                    }

                    const cityQuestion = props.group.questions.find(q => q.controlType.toLowerCase() === 'city');
                    if (cityQuestion && result.city) {
                        props.onChange(cityQuestion, result.city);
                    }
                }
            });
        }
    }

    return (
        <TextInput disabled={props.disabled} className={props.className} value={props.value} onChange={handleChange}>
            {props.children}
        </TextInput>
    );
}

export default PostalCodeInput;