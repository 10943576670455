import React, { useContext, useEffect, useState } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';
import { AppContext } from '../../AppContext';

interface Props {
    value?: SelectOption,
    className?: string,
    countryID?: number,
    onChange: (value: { label: string, value: any } | null) => void,
    placeholder?: string,
}

const StateDropdown = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        FlowProvider.GetStates(props.countryID).then((result) => {
            if (result) {
                if (context.vendorSettings["ExcludedStateIDs"]?.length > 0)
                    result.options = result.options.filter(x => !context.vendorSettings["ExcludedStateIDs"].includes(x.value));
                setOptions(result.options);

                if (props.value !== null && props.value !== undefined && result.options.findIndex(x => x.value === props.value?.value) === -1) {
                    props.onChange(null);
                }
            } else if (props.value !== null && props.value !== undefined) {
                props.onChange(null);
            }
        })
    }, [props.countryID]);

    // Browser autofill will set the value to the label instead of the value
    useEffect(() => {
        if (props.value && !Number.isInteger(props.value.value)) {
            let found = options.find(x => x.label.toLowerCase() === props.value?.label?.toLowerCase());

            if (found) {
                props.onChange(found);
            }
        }
    }, [props.value]);

    return (
        <DropdownInput searchable className={props.className} containerProps={{ className: 'label-colorize' }} value={props.value} onChange={props.onChange} options={options} placeholder={props.placeholder}>
            {props.children}
        </DropdownInput>
    )
}

export default StateDropdown;