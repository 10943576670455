import React, { useContext, useEffect, useState } from 'react';
import { DropdownInput } from 'sandhills-react-bootstrap-ui';
import { SelectOption } from '../../models/SelectOption';
import FlowProvider from '../../providers/FlowProvider';
import { StepQuestion } from '../../models/StepQuestion';
import { AppContext } from '../../AppContext';

interface Props {
    value?: SelectOption,
    className?: string,
    disabled?: boolean,
    onChange: (value: { label: string, value: any } | null) => void,
    dependantQuestions: StepQuestion[],
    questionValues?: { [inputDefinitionID: number]: any },
}

const CountryDropdown = (props: React.PropsWithChildren<Props>) => {
    const context = useContext(AppContext);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const questionValues = props.questionValues || {};
    const countryQuestion = props.dependantQuestions?.find(x => x.name.includes("Country"));
    const countryValue = (countryQuestion && questionValues[countryQuestion.inputDefinitionID]) || undefined;

    useEffect(() => {
        FlowProvider.GetCountries(context.languageID).then((result) => {
            if (result) {
                setOptions(result.options);
                if (countryValue && props.value === null && result.options.findIndex(x => x.value === countryValue) > -1) {
                    props.onChange(result.options[result.options.findIndex(x => x.value === countryValue)]);
                }
                else if (result.options.findIndex(x => x.value === props.value?.value) === -1) {
                    props.onChange(null);
                }
            } else {
                props.onChange(null);
            }
        })
    }, [context.languageID]);

    // Browser autofill will set the value to the label instead of the value
    useEffect(() => {
        if (props.value && !Number.isInteger(props.value.value)) {
            let found = options.find(x => x.label.toLowerCase() === props.value?.label?.toLowerCase());

            if (found) {
                props.onChange(found);
            }
        }
    }, [props.value]);

    return (
        <DropdownInput searchable value={props.value} className={props.className} containerProps={{ className: 'label-colorize' }} onChange={props.onChange} options={options} disabled={props.disabled}>
            {props.children}
        </DropdownInput>
    )
}

export default CountryDropdown;