declare global {
    interface String {
        format(...args: any[]): string;
    }
}

// This class handles translations
export default class TranslationProvider {
    private translations: { [key: string]: string };
    private debugMode: boolean = false;

    constructor(translations: { [key: string]: string }, debugMode: boolean = false) {
        this.translations = translations;
        this.debugMode = debugMode;

        if (this.debugMode)
            window.alert('Translations are in debug mode. "NF-" means there is a missing key, or the key hasn\'t been linked to the namespace. "**{translation}**" means a valid translation would be shown and no changes are necessary. Anything showing up in english was missed and still needs to be replaced with a translation.');

        // Custom string format function
        if (!String.prototype.format) {
            String.prototype.format = function () {
                var args = arguments;
                return this.replace(/{(\d+)}/g, function (match, number) {
                    return typeof args[number] != 'undefined'
                        ? args[number]
                        : match
                        ;
                });
            };
        }
    }

    get(key: string, ...formatArgs: string[]): string {
        if (!key) {
            console.error('A key is required to use translations.');
            return '';
        }

        let lowerKey = key.toLowerCase();

        if (this.translations) {
            if (this.translations[lowerKey]) {

                let translation = this.translations[lowerKey];
                if (formatArgs && formatArgs.length > 0) {
                    if (this.debugMode)
                        return `**${translation.format(...formatArgs) }**`;
                    return translation.format(...formatArgs);
                }

                if (this.debugMode)
                    return `**${translation}**`;

                return translation;
            }
//            else {
//                    console.info(`%c
//Translation key '${lowerKey}' is missing, or hasn't been linked to this namespace. Follow the steps below to fix the issue: \n\n
//• Search for matching or similar english phrases here: http://intranet/TranslationsMVC/  \n
//• If needed, add a new key / english phrase here: http://intranet/TranslationsMVC/Key/Add?keyName=${encodeURIComponent(lowerKey)}  \n
//• Link the key to our namespace here: http://intranet/TranslationsMVC/Namespace/AddNamespaceLink?keyName=${encodeURIComponent(lowerKey)}&namespace=${encodeURIComponent('Sandhills.Currency')}  \n\n
//• Clear the cache after adding all missing keys: https://${window.location.host}/cache-handler/search?searchTerm=Translations_Get  \n\n

//Note - Only add a new key if an existing one can't be used. Make sure to use the correct key in the third step if one already exists.
//                `, 'color: #4880DB');
//            }
        } else {
            console.error('TranslationProvider was not initialized with translations.');
        }
        if (this.debugMode)
            return `NF-${key}`;
        return key;

    }

    getComponentFormat(translationKey: string, inserts: any): any[] {
        var translation = this.get(translationKey);
        var outputs = translation.split("{");
        outputs = outputs.map(x => x.split("}")).flat();
        if (outputs.length === 1)
            return outputs;

        const keys = Object.keys(inserts);
        keys.forEach(key => {
            outputs.forEach((x, i) => {
                if (x === key)
                    outputs[i] = inserts[key];
            });
        });
        return outputs;
    } 

    exists(key: string): boolean {
        if (!key) {
            console.error('No key provided.');
            return false;
        }

        let lowerKey = key.toLowerCase();

        return this.translations && this.translations[lowerKey] !== null && this.translations[lowerKey] !== undefined && this.translations[lowerKey] !== '';
    }

    
}