// THIS FILE WAS GENERATED AUTOMATICALLY
//
// DO NOT EDIT THIS FILE DIRECTLY

import { AppInitialization } from '../models/AppInitialization';
import { Translations } from '../models/Translations';
import { Translation } from '../models/Translation';
import { Base } from '../models/Base';


import FetchProvider from './client/FetchProvider';

export default class AppProvider {
    // -------------------------------------------------------------------------
    // ------------------------------ GET METHODS ------------------------------
    // -------------------------------------------------------------------------

    static InitializeApp(vendorID?: number | null | undefined, TLPID?: number | null | undefined, applicationGUID?: string | null | undefined, cultureCode?: string | null | undefined): Promise<AppInitialization | null | undefined> {
        return FetchProvider.fetch<AppInitialization>(`/api/app/initializeapp?vendorID=${encodeURIComponent(vendorID || '')}&TLPID=${encodeURIComponent(TLPID || '')}&applicationGUID=${encodeURIComponent(applicationGUID || '')}&cultureCode=${encodeURIComponent(cultureCode || '')}`);
    }

    static GetTranslations(languageID: number | null | undefined): Promise<Translations | null | undefined> {
        return FetchProvider.fetch<Translations>(`/api/app/gettranslations?languageID=${encodeURIComponent(languageID || '')}`);
    }

    static GetTranslation(languageID: number | null | undefined, translationKey: string | null | undefined): Promise<Translation | null | undefined> {
        return FetchProvider.fetch<Translation>(`/api/app/gettranslation?languageID=${encodeURIComponent(languageID || '')}&translationKey=${encodeURIComponent(translationKey || '')}`);
    }

    // --------------------------------------------------------------------------
    // ------------------------------ POST METHODS ------------------------------
    // --------------------------------------------------------------------------

    static LogApplicationInsights(input: any | null): Promise<Base | null | undefined> {
        return FetchProvider.fetch<Base>(`/api/app/logapplicationinsights`, input);
    }
}