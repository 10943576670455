import React, { useContext, useState } from 'react';
import { InputNote } from 'sandhills-react-bootstrap-ui';
import { AppContext } from '../../AppContext';
import Context from '../../models/client/Context';

interface Props {
    id: string,
    value?: boolean,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}

const DevelonTermsAndAgreementsCheck = (props: Props) => {
    const context: Context = useContext(AppContext);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

    const handleScroll = (e: React.UIEvent) => {
        // Check if the user has scrolled to the bottom of the div
        const element = e.target as HTMLDivElement;
        if (element.scrollHeight - element.scrollTop <= element.clientHeight + 50) {
            setHasScrolledToBottom(true);
            props.onChange(true);
        }
    }

    return (
        <div>
            {!context.oemDealerType?.includes("Canadian") && !context.oemDealerType?.includes("Canada") ?
                <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                    <p>
                        {context.translations.get("newAppDisclosure1")}
                    </p>
                    <p>
                        IMPORTANT INFORMATION: Except as otherwise prohibited by law, you agree and consent that HD Hyundai Infracore North America
                        LLC fka Doosan Infracore North America LLC and dba DEVELON ("HDINA") may share information with potential lenders about the
                        Applicant(s) that HDINA has or may obtain for the purposes, among other things, of evaluating credit applications or
                        servicing account(s). ECOA Notice: The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against
                        credit applicants on the bases of race, color, religion, national origin, sex, marital status, age (providing the applicant
                        has the capacity to enter into a binding contract); because all or part of the applicant's income derives from any public
                        assistance program; or, because the applicant has in good faith exercised any right under the Consumer Credit Protection Act.
                        The federal agency that administers compliance with this law concerning this creditor is Bureau of Consumer Financial Protection,
                        1700 G Street NW, Washington, DC 20008.
                    </p>
                    <p>
                        "You," the "Applicant" (both terms include the business entity as well as all of the individuals named above), certify to us
                        that you are applying for credit for business reasons, and not for personal, family or household purposes. Applicant authorizes
                        HDINA and potential lenders to obtain information from others concerning Applicant's credit and trade standing, including
                        Applicant's personal credit report (if Applicant is sole proprietorship or if named individuals are providing guaranty), and
                        other relevant information impacting this application, and if the Lease or Loan is approved, from time to time during the term
                        of the Lease or Loan. You authorize HDINA and potential lenders to prepare and file against Applicant, a financing statement,
                        in form and substance sufficient to perfect a security interest in collateral together with the proceeds thereof arising from
                        an approved Lease or Loan. In addition to the information requested on this application, HDINA and potential lenders may subsequently
                        request additional information from Applicant. As an authorized agent of the applicant company, you represent that you have reviewed
                        this document and the information herein is true, correct and complete. A photo static copy of this authorization shall be as valid
                        as the original. If HDINA or potential lenders decline this application, you authorize potential lenders to advise HDINA and your
                        dealer of the decline and the reasons for the decline and to provide a copy of this application to HDINA. You authorize HDINA to
                        provide a copy of this application to other lenders identified by HDINA in their sole discretion. You agree that any lender that
                        receives a copy of this application from HDINA on your behalf is authorized to obtain information about you as described in this
                        application.
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure6")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure7")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure8")}
                    </p>
                    <p>
                        {context.translations.get("newAppDisclosure4")}
                    </p>
                </div>
                :
                <div onScroll={handleScroll} className='border rounded p-2 bg-white' style={{ height: 300, maxHeight: 300, overflowY: 'auto' }}>
                    <p>
                        NA
                    </p>
                </div>
            }
            <InputNote>{(!hasScrolledToBottom && props.className === 'input-error') && context.translations.get('currency.scrollthroughagreement')}&nbsp;</InputNote>
        </div>
    );
}

export default DevelonTermsAndAgreementsCheck;