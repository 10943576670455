import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageLoading from '../../shared/PageLoading';
import ApplicationProvider from '../../providers/ApplicationProvider';
import { Button, Col, Container, ListGroup, ProgressBar, Row } from 'react-bootstrap';
import { Stipulation } from '../../models/Stipulation';
import { FaCheck, FaFile, FaHourglass, FaMinus, FaTimes, FaUpload } from 'react-icons/fa';
import HiddenFileSelect from '../../shared/HiddenFileSelect';
import { StipulationFileUpload } from '../../models/StipulationFileUpload';
import Separator from '../../shared/Separator';
import { AppContext } from '../../AppContext';

interface Props {
    isForVendor?: boolean
}

const ApplicationDetail = (props: Props) => {
    const context = useContext(AppContext);

    const { applicationGUID } = useParams();
    const [loading, setLoading] = useState<boolean>(true);

    const activeStipulationID = useRef<number | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [stipulations, setStipulations] = useState<Stipulation[]>([]);

    const [uploadStatus, setUploadStatus] = useState<{ [key: number]: { current: number, success: boolean } }>({});

    useEffect(() => {
        if (applicationGUID) {
            getStipulationsForApplication(applicationGUID);
        }
    }, []);

    const getStipulationsForApplication = (applicationGUID: string) => {
        setLoading(true);
        ApplicationProvider.GetStipulationsForApplication(applicationGUID, props.isForVendor).then((result) => {
            if (result) {
                setStipulations(result.stipulations);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const uploadFileForStipulation = (stipulationID: number, file: File) => {
        setUploadStatus(current => ({ ...current, [stipulationID]: { current: 10, success: true } }));

        const input: StipulationFileUpload = {
            stipulationID: stipulationID,
            file
        }

        // Fake upload progress
        const int = setInterval(() => {
            setUploadStatus(current => ({ ...current, [stipulationID]: current[stipulationID] !== undefined && current[stipulationID]!.current <= 70 ? { current: current[stipulationID]!.current + 5, success: true } : current[stipulationID] }));
            if (uploadStatus[stipulationID] && uploadStatus[stipulationID]!.current > 70) {
                clearInterval(int);
            }
        }, 500);

        ApplicationProvider.UploadFileForStipulation(input).then((result) => {
            if (result?.success) {
                // Mark this one as submitted
                setUploadStatus(current => ({ ...current, [stipulationID]: { current: 100, success: true } }));
                // Update the submitted file name
                setStipulations(current => current.map(stipulation => stipulation.stipulationID === stipulationID ? { ...stipulation, documentName: file.name, statusName: 'Submitted' } : stipulation));
            } else {
                setUploadStatus(current => ({ ...current, [stipulationID]: { current: 100, success: false } }));
            }
        }).finally(() => {
            clearInterval(int);
        });
    }

    const handleFilesSelected = (files: File[]) => {
        if (files.length > 0 && activeStipulationID.current) {
            uploadFileForStipulation(activeStipulationID.current, files[0]);
        }
        activeStipulationID.current = null;
    }

    const handleFilesDropped = (e: React.DragEvent, stipulationID: number, disabled: boolean) => {
        e.preventDefault();
        e.currentTarget.classList.remove('active');

        if (disabled)
            return;

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            let file = e.dataTransfer.items[0].getAsFile();

            uploadFileForStipulation(stipulationID, file!);
        }
    }

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.effectAllowed = "all";
        e.dataTransfer.dropEffect = "copy";
        e.currentTarget.classList.add('active');
    }

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.currentTarget.classList.remove('active');
    }

    const handleSelectFileClick = (stipulationID: number) => {
        activeStipulationID.current = stipulationID;
        fileInputRef.current?.click();
    }

    const getIconColorForStatus = (status: string) => {
        switch (status.toUpperCase()) {
            case 'SUBMITTED':
                return 'text-primary';
            case 'WAIVED':
                return 'text-light-gray';
            case 'CLEARED':
                return 'text-success';
            case 'OPEN':
                return 'text-warning';
            case 'REJECTED':
                return 'text-danger';
            default:
                return '';
        }
    }

    const getIconForStatus = (status: string) => {
        const style = {
            zIndex: 3,
            transform: 'translateY(5px)'
        };

        switch (status.toUpperCase()) {
            case 'SUBMITTED':
                return <FaUpload style={style} className='text-white position-absolute' size={16} />
            case 'WAIVED':
                return <FaMinus style={style} className='text-white position-absolute' size={16} />
            case 'CLEARED':
                return <FaCheck style={style} className='text-white position-absolute' size={16} />
            case 'OPEN':
                return <FaHourglass style={style} className='text-white position-absolute' size={16} />
            case 'REJECTED':
                return <FaTimes style={style} className='text-white position-absolute' size={16} />
            default:
                return null;
        }
    }

    if (loading)
        return <PageLoading />

    return (
        <Container fluid='md' className='py-3'>
            <HiddenFileSelect forwardedRef={fileInputRef} onChange={handleFilesSelected} />
            {props.isForVendor && stipulations.length > 0 && stipulations[0].responsibleParty.toLowerCase() === 'vendor' && <Separator label={context.translations.get('currency.dealerfulfilleddocuments')} />}
            {stipulations.length > 0 && stipulations.map((stipulation, i) => {
                const disabled = ['WAIVED', 'CLEARED'].includes(stipulation.statusName.toUpperCase());

                return (
                    <React.Fragment key={stipulation.stipulationID}>
                        {i > 0 && stipulations[i - 1].responsibleParty !== stipulation.responsibleParty && <Separator label={context.translations.get('currency.customerfulfilleddocuments')} />}
                        <ListGroup.Item key={stipulation.stipulationID} className='border rounded shadow w-100 p-0 mb-3'>
                            <Row className='p-3'>
                                <Col md='1' xs='2' className='text-center text-md-left'>
                                    <div className='d-inline-flex position-relative align-items-center justify-content-center'>
                                        <FaFile className={getIconColorForStatus(stipulation.statusName)} size={50} />
                                        {getIconForStatus(stipulation.statusName)}
                                    </div>
                                </Col>
                                <Col md xs='10' style={{ overflow: 'hidden' }} className='text-truncate'>
                                    <h5 className='text-condensed mb-0'>{stipulation.stipulationType}</h5>
                                    <small style={{ whiteSpace: 'nowrap' }} className='text-muted'>{stipulation.statusName} {stipulation.documentName ? ` - ${stipulation.documentName}` : ''}</small>
                                </Col>
                                <Col md xs='12' className='mt-2 mt-md-0'>
                                    {((props.isForVendor && stipulation.responsibleParty.toLowerCase() === 'vendor') || (!props.isForVendor && stipulation.responsibleParty.toLowerCase() === 'customer')) && <Button
                                        onDrop={(e) => handleFilesDropped(e, stipulation.stipulationID, disabled)}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDragEnter={handleDragOver}
                                        onClick={() => handleSelectFileClick(stipulation.stipulationID)}
                                        disabled={disabled}
                                        style={{ border: '3px dashed', minHeight: 50 }}
                                        className={`${disabled ? 'border-light-gray' : 'border-primary'} w-100 h-100`}
                                        variant='outline-primary'
                                    >
                                        {context.translations.get('currency.clickordragfile')}
                                    </Button>}
                                </Col>
                            </Row>
                            {uploadStatus[stipulation.stipulationID] !== undefined &&
                                <ProgressBar style={{ height: 5 }} variant={uploadStatus[stipulation.stipulationID]!.success ? 'primary' : 'danger'} max={100} now={uploadStatus[stipulation.stipulationID].current} />
                            }
                        </ListGroup.Item>
                    </React.Fragment>
                )
            })}

            {stipulations.length === 0 &&
                <div style={{ fontSize: 20 }} className='d-flex bg-white shadow rounded w-100 p-4 text-condensed align-items-center justify-content-center'>
                    {context.translations.get('currency.nodocumentuploads')}
                </div>
            }
        </Container>
    );
}

export default ApplicationDetail;