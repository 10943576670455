import React, { useContext } from 'react';
import { AlertModal, CheckInput, InputNote } from 'sandhills-react-bootstrap-ui';
import { Button } from 'react-bootstrap';
import { AppContext } from '../../AppContext';

interface Props {
    id: string,
    value?: boolean,
    label?: string,
    className?: string,
    required?: boolean | null,
    onChange: (value: boolean) => void
}


const DoNotShareCheck = (props: Props) => {
    const context = useContext(AppContext);

    const explanationPrompt = (
        <div>
            <p>
                {context.translations.get('currency.donotshareexplanation')}
            </p>

            <p>
                {context.translations.get('currency.donotshareexplanation2')}
            </p>

            <p>
                {context.translations.get('currency.donotshareexplanation3')}
            </p>

            <p>
                {context.translations.get('currency.donotshareexplanation4')}
            </p>

            <p>
                {context.translations.get('currency.donotshareexplanation5')}
            </p>

            <p>
                {context.translations.get('currency.donotshareexplanation6')}
            </p>

            <h6><strong>{context.translations.get('currency.ismyinfosecure')}</strong></h6>
            <p>{context.translations.get('currency.weprideourselvesinsecurity')}</p>
        </div>
    )
    const handleExplanationClick = () => {
        AlertModal.alert(context.translations.get('currency.SharingOfContactInformation'), explanationPrompt, 'primary', 'lg')
    }

    return (
        <CheckInput id={props.id} className={`${props.className ?? ''} mr-1`} value={props.value} onChange={props.onChange} label={`${props.label}${props.required ? '*' : ''}`}>
            <InputNote style={{ marginTop: '-10px' }}>
                <Button className='px-0 accessible-gray' size='sm' variant="link" style={{ fontSize: '9pt' }} onClick={handleExplanationClick}>{context.translations.get("Currency.HowDoesThisAffectMyApplication")}</Button>
            </InputNote>
        </CheckInput>
    );
}

export default DoNotShareCheck;